import { AccountCircleOutlined, PhoneOutlined } from "@mui/icons-material";
import { Autocomplete, Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, showAlert } from "../../actions";
import * as yup from "yup";
import { updateUserInfo } from "../../API";
import countriesCodes from "../../data/countriesCodes";
import { t } from "../../services/i18n";
import { LoadingButton } from "@mui/lab";


export default function EditInformations() {
	const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.UserReducer);
  const countryCode = countriesCodes.find(code => code.phone === userData.CountryCode);
  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting } = useFormik({
		initialValues: {
			name: userData.Name,
			phone: userData.Phone,
      countryCode: countryCode?.phone || ''
		},
		validationSchema: yup.object({
      name: yup
        .string()
        .required(t("common:Name_is_required")),
      countryCode: yup
        .string()
        .required(t("common:country_code_is_required")),
      phone: yup
        .string()
        .required(t("common:Phone_field_is_required")),
    }),
		async onSubmit({ name, phone, countryCode }) {
      let values = {
        Name: name,
        Phone: phone,
        CountryCode: countryCode
      };
      let res = await updateUserInfo(values);

      if (res.error === false) {
        dispatch(showAlert("success", t("common:your_data_updated_successfully")));
        const user = JSON.parse(JSON.stringify(userData));
        user.Name = name;
        user.Phone = phone;
        user.CountryCode = countryCode;
        dispatch(setUserData(user, true));
      } else {
        dispatch(showAlert("error", res.message || res.error));
      }
		},
	});

  return <>
    <Typography variant="h4">{t("common:Edit_Info")}</Typography>
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth margin="normal" color='primary'
        name="name" label={t("common:Name*")}
        placeholder="Ali Alami"
        autoFocus
        value={values.name} onChange={handleChange}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlined />
            </InputAdornment>
          ),
        }}
      />

      <Stack direction='row' gap={1}>
        <Autocomplete
          sx={{ mt: 2, width: '50%' }}
          options={countriesCodes}
          autoHighlight
          defaultValue={countryCode}
          onChange={(e, value) => setFieldValue('countryCode', value?.phone || '')}
          getOptionLabel={(option) => option.phone}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.code}) +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='countryCode' label={t('common:countryCode')}
              error={touched.countryCode && Boolean(errors.countryCode)}
              helperText={touched.countryCode && errors.countryCode}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />

        <TextField
          margin="normal" color='primary'
          name="phone" label={t("common:Phone*")}
          placeholder="634654876"
          sx={{ width: '50%' }}
          value={values.phone} onChange={handleChange}
          error={touched.phone && Boolean(errors.phone)}
          helperText={touched.phone && errors.phone}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        loading={isSubmitting}
        type="submit"
        fullWidth
        color='primary'
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        disableElevation
      >
        {t("common:Save")}
      </LoadingButton>
    </form>
  </>
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { createTheme, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { enUS, frFR, arEG } from '@mui/material/locale';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import i18n from './services/i18n';

if (i18n.queryLocale) {
  localStorage.setItem('locale', i18n.queryLocale);
}

const queryClient = new QueryClient()

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      // danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      // danger?: string;
    };
  }
}

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: i18n.dir === 'rtl' ? [prefixer, rtlPlugin] : [prefixer],
});

const locale = localStorage.getItem('locale') || 'fr';
let lang = frFR
if (locale === 'en') {
  lang = enUS;
} else if (locale === 'fr') {
  lang = frFR;
} else if (locale === 'ar') {
  lang = arEG;
}

const theme = createTheme(
  {
    palette: {
      primary: { 
        main: '#53b3a6',
        contrastText: 'white'
      },
      success: {
        main: '#53b3a6'
      },
      warning: {
        main: '#ffa000'
      },
      text: {
        primary: '#414141'
      },
      secondary: { main: '#919797' },
      
      // secondary: { main: '#666666' },
    },
    status: {
      // danger: orange[500],
    },
    direction: i18n.dir
  },
  lang
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <div dir={i18n.dir}>
        <CacheProvider value={cacheRtl}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Provider>
        </CacheProvider>
      </div>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
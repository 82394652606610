import { useState, useEffect } from 'react'

export default function useBreakPoints() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    
      window.addEventListener("resize", handleResize);
     
      handleResize();
    
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const breakPoints = {
    'xs': 640 ,
    'sm': 640 ,
    'md': 768 ,
    'lg': 1024 ,
    'xl': 1280 ,
    '2xl': 1536 ,
  }

  return { windowSize, breakPoints };
}
import actionTypes from "../actions/types";
const initialState = {
  userData: {},
  isLoggedIn: false,
  isRegistration: false,
  location: {
    address: "",
    lat: null,
    long: null,
  },
};

const UserReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        userData: action.payload.user,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case actionTypes.SET_USER_SHOP_ID:
      return {
        ...state,
        userData: { ...state.userData, Shop_id: action.payload.Shop_id },
      };
      case actionTypes.SET_USER_SHOP:
        return {
          ...state,
          userData: {
            ...state.userData,
            shop: action.payload.shop,
          },
        };
    case actionTypes.SET_USER_PHONE_AND_NAME:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      };
    case actionTypes.SET_IS_REGISTRATION:
      return {
        ...state,
        isRegistration: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;

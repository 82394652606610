import actionTypes from "../actions/types";
const initialState = {
  showAppIntro: "true",
  cartCount: 0,
  cartItems: [],
  totalPrice: 0,
  order: {},
  loading: true,
};

const LayoutReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case "setInit":
      return {
        ...state,
        // showAppIntro: action.showAppIntro,
        showAppIntro: 'true',
      };
    case actionTypes.SET_APP_INTRO:
      return {
        ...state,
        showAppIntro: action.payload.show,
      };
    case actionTypes.UPDATE_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload.cartItems,
        cartCount: action.payload.cartCount,
        totalPrice: action.payload.totalPrice,
      };
    case actionTypes.SET_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case actionTypes.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload.value
      }

    default:
      return state;
  }
};

export default LayoutReducer;

import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import NotificationsReducer from "./NotificationsReducer";
import OrderReducer from "./OrderReducer";
import AlertReducer from "./AlertReducer";
import ProductsReducer from "./ProductsReducer";

const reducers = combineReducers({
  UserReducer,
  LayoutReducer,
  NotificationsReducer,
  OrderReducer,
  ProductsReducer,
  alert: AlertReducer,
});

export default reducers;

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useMemo } from 'react';
import i18n, { t } from '../services/i18n';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function encode(service: any) {
  return service.id + ' ' + service.name; 
}

function decode(joined: any) {
  const id = joined.split(' ')[0];
  const name = joined.slice(id.length + 1);
  console.log(id, '-', name);
  return { id, name }; 
}

export default function AdditionalServicesSelect({ 
  additionalServices,
  specialServicesIds, setSpecialServicesIds
}: any) {
  const specialServices = useMemo(() => {
    return specialServicesIds.map((id: any) => {
      return additionalServices.find((item: any) => item.id === id);
    });
  }, [specialServicesIds, additionalServices])

  function handleChange(e: any) {
    setSpecialServicesIds(e.target.value.map((service: any) => decode(service).id));
  }

  return (
    <div>
      <FormControl fullWidth sx={{ mt : 3 }}>
        <InputLabel id="demo-multiple-chip-label">{t("common:additional_services_title")}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={specialServices.map((service: any) => encode(service))}
          onChange={handleChange}
          input={<OutlinedInput dir={i18n.dir} label={t("common:additional_services_title")} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.map((joinedService: any) => {
                  const { id, name } = decode(joinedService);
                  return <Chip key={id} label={name} />;
                })
              }
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {
            additionalServices.map((additionalService: any) => (
              <MenuItem
                key={additionalService}
                value={encode(additionalService)}
              >
                {additionalService.name} (+{additionalService.price} dhs)
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
}
import { Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Cart from '../Cart';
import ComplaintModal from '../ComplaintModal';
import NavigationDrawer from '../NavigationDrawer';
import SignInModal from '../SignInModal';
import LocaleMenu from './LocaleMenu';

export const ComplaintModalContext = createContext<any>([]);

export default function _AppBar() {
  const [openComplaintModal, setOpenComplaintModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { userData } = useSelector((state: any) => state.UserReducer);

  const hideLinks = (
    ['/address-selection', '/add-address', '/edit-address', '/shop-selection'].includes(pathname) &&
    !userData?.Shop_id
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} sx={{ color: 'white' }}>
        <Toolbar>
          <ComplaintModalContext.Provider value={{ openComplaintModal, setOpenComplaintModal }}>
            <NavigationDrawer />
            <ComplaintModal />
          </ComplaintModalContext.Provider>
          
          {
            hideLinks && (
              <LocaleMenu />
            )
          }

          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => navigate('/')}
            sx={{ display: { xs: 'none', sm: 'block' }, cursor: 'pointer' }}
          >
            Cleanzy
          </Typography>


          <Box sx={{ flexGrow: 1 }} />
          {
            !hideLinks && (
              <Stack direction='row' gap={1}>
                <LocaleMenu />
                <Cart handleOpenSignInModal={() => setOpenSignInModal(true)} />
              </Stack>
            )
          }
        </Toolbar>
      </AppBar>

      <SignInModal
        open={openSignInModal} 
        handleClose={() => setOpenSignInModal(false)} 
      />
    </Box>
  );
}
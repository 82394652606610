import api from "../config/keys";
// import store from "../store";

/**
 * create user
 *
 */
export const createUser = async (values) => {
  let res = await fetch(`${api.apiURL}/user/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};
/**
 *  get user data
 *
 */
export const getUser = async () => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/user/getOne/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  }).catch((err) => {
    console.log(`error /user/getOne/ ${err}`);
  });

  return await res.json();
};

/**
 *  get user orders
 *
 */
export const getUserOrders = async () => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/order/getByUser`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};
/**
 *  get all shops
 *
 */
export const getShops = async () => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/shop/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  }).catch((err) => console.log(err));

  return await res.json();
};
/**
 *  get all shops Geolocation
 *
 */
export const getShopsGeo = async () => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/shop/getGeo`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};

/**
 *  get all categories
 *
 */
export const getCategories = async () => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/category/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};

/**
 *  add user address
 *
 */
export const AddAddress = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/address/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  update  user address
 *  @param {OBJECT} values `
 *  { new_address:{
 *    id: address.id,
        Geolocation: {
          lat: markerLocation.latitude,
          long: markerLocation.longitude,
        },
        FirstLine: address1,
        SecondLine: address2,
        Country: country,
        City: city,
        PostalCode: zipcode,
        Uid: userData && userData?.uid,
        Is_default: Is_default,
       },
     }`
 *
 */
export const updateAddress = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/address/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  add  new user address
 *  @param {OBJECT} values `
 *  { new_address:{
       Geolocation: {
        lat: markerLocation.latitude,
        long: markerLocation.longitude,
      },
      name: address,
      Uid: userData && userData?.uid,
      Is_default: true,
       },
      current_address:"id"
     }`
 *
 */
export const AddNewAddress = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/address/addNew`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  Delete Address User
 * @param {Object} values
 *
 */
export const deleteAddress = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/address/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  get Products by category
 *
 */
export const getProductsByCategory = async (category) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/product/getByCateg/${category}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};

/**
 *  get shop Products by shop id
 *
 */
export const getShopProductsById = async (id) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/shop/getProduc/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};

/**
 *  get default store products
 *
 */
export const getdefaultShopProducts = async () => {
  let res = await fetch(`${api.apiURL}/product/getdefaultShopProducts`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    },
  }).catch((err) => {
    console.log(err);
  });

  return await res.json();
};

/**
 *  get products
 *
 */
export const getProducts = async () => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/product/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  }).catch((err) => {
    console.log(err);
  });

  return await res.json();
};

/**
 *  get subscriptions
 *
 */
export const getSubscriptions = async () => {
  let res = await fetch(`${api.apiURL}/product/getSubs`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  return await res.json();
};

/**
 *  create order
 *  @param {OBJECT} values
 *
 */
export const createOrder = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/order/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  Delete Order
 *  @param {String} id
 *
 */
export const deleteOrder = async (id) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/order/delete/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};

/**
 *  check coupon
 *  @param {OBJECT} values `code and price`
 *
 */
export const checkCoupon = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/coupon/reducedPrice`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  check delivery fees
 *  @param {NUMBER} price
 *
 */
export const checkFees = async (price) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/delivery/fees`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ price }),
  });

  return await res.json();
};

/**
 *  send Forgot password email
 *  @param {String} Email
 *
 */
export const forgotPassword = async (email) => {
  let res = await fetch(`${api.apiURL}/user/forgotPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({ email }),
  });

  return await res.json();
};

/**
 * reset password
 *  @param {Object} Values
 *
 */
export const resetPassword = async (values) => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/user/resetPassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 * update user info
 *  @param {Object} Values
 *
 */
export const updateUserInfo = async (values) => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/user/update`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 * update user phone number
 *  @param {Object} Values
 *
 */
 export const updateUserPhoneNumber = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/user/updatephone`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  }).catch(() => {
    return "{success: false}"
  });
  return await res.json();
};

/**
 * update user object
 *  @param {Object} Values
 *
 */
export const updateUser = async (data) => {
  try {
    let token = localStorage.getItem("token");
    let res = await fetch(`${api.apiURL}/user/updateSpecificFields`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }).catch(() => {
      return {success: false}
    });
    return await res.json();
  } catch (error) {
    return {success: false}
  }
};

/**
 * Get delivery guy details (User + delivery details)
 *  @param {Object} deliveryId
 *
 */
 export const getDeliveryGuy = async (data) => {
  try {
    let token = localStorage.getItem("token");
    let res = await fetch(`${api.apiURL}/deliveryStaffs/getguy`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }).catch((err) => {
      console.log(err);
      return {success: false}
    });
    return await res.json();
  } catch (error) {
    return {success: false}
  }
};


/**
 * update user avatar
 *  @param {Object} Values
 *
 */
export const updateUserAvatar = async (avatar) => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/user/updateAvatar`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ avatar }),
  });

  return await res.json();
};

/**
 * delete user account
 *
 */
export const deleteUserAccount = async () => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/user/delete`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });

  return await res.json();
};

/**
 * get the availabel hours from a date on shop
 *
 */
export const getAvailableHours = async (values) => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/delivery/availHours`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  save contact from
 *
 */
export const SaveContactForm = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/user/saveContactForm`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });
  return await res.json();
};

/**
 *  add new complaint
 *
 */
export const addComplaint = async (values) => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/user/addComplaint`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  add new review
 *
 */
export const addRate = async (values) => {
  let token = localStorage.getItem("token");

  let res = await fetch(`${api.apiURL}/user/addRate/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 *  get Save Push Token
 *  @param {object} `values`
 *
 */
export const saveToken = async (values) => {
  let res = await fetch(`${api.apiURL}/notification/saveNotificationToken`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};
/**
 *  update order status
 * @param {Object} values
 * @returns
 */
export const updateOrder = async (values) => {
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/order/update`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(values),
  });

  return await res.json();
};

/**
 * get the default shop data
 */
export const getDefaultShopData = async () => {
  
  let token = localStorage.getItem("token");
  let res = await fetch(`${api.apiURL}/shop/getdefault`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token,
    },
  });
  
  return await res.json();
};

import { EmailOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  InputAdornment,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { showAlert } from "../actions";
import { forgotPassword } from "../API";
import i18n, { t } from "../services/i18n";

export default function ForgotPasswordDialog({ open, handleClose }: any) {
  const dispatch = useDispatch();

  const { values, errors, touched, handleSubmit, handleChange, resetForm, isSubmitting } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t("common:Email_invalid_please_use_the_correct_format"))
        .required(t("common:Email_field_is_required")),
    }),
    async onSubmit({ email }) {
      let res = await forgotPassword(email);
      if (res.error === false) {
        dispatch(showAlert('success', t("common:an_email_has_been_sent_to_you_with_the_link_to_reset_your_account")));
        handleClose();
      } else  {
        dispatch(showAlert("error", res.message || res.error));
      }
      resetForm();
    }
  });

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ textAlign: 'left' }}>{t("common:Forgot_Password_title")}</DialogTitle>
        <DialogContent>
          <TextField
            dir={i18n.dir}
            fullWidth margin="normal" color='primary' 
            name="email" label={t("common:Your_email")}
            placeholder={t("common:email_placeholder")}
            autoFocus
            value={values.email} onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common:Cancel")}</Button>
          <LoadingButton 
            loading={isSubmitting}
            type="submit" 
            variant='contained' 
            disableElevation
          >
            {t("common:Continue")}
          </LoadingButton>
        </DialogActions>
      </form>
		</Dialog>
	);
}

import { Button, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import * as React from 'react';
import { t } from '../../services/i18n';

function Shops({ isLoading, shops, selectedShop, setSelectedShop, handleShopPick }: any) {
  return (
    <div className="control-panel">
      <h3>{t("common:Choose_your_shop")}</h3>
      <p>{t("common:List_of_available_shops")}</p>
      {
        isLoading ? (
          <CircularProgress />
        ) : (
          <List>
            {shops?.map((shop: any, i: number) => (
              <ListItem 
                selected={shop.id === selectedShop?.id}
                button onClick={() => setSelectedShop(shop)} 
                key={i}
                // sx={shop.id === selectedShop?.id ? selectedStyle : {}}
              >
                <ListItemText primary={shop.name} />
              </ListItem>
            ))}
          </List>
        )
      }

      <Button
        fullWidth
        color='primary'
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        disableElevation
        disabled={!selectedShop}
        onClick={handleShopPick}
      >
        {t("common:Continue")}
      </Button>
    </div>
  );
}

export default React.memo(Shops);
import * as React from 'react';
import logo from '../../images/logo-dark.jpg';

const pinStyle = {
  cursor: 'pointer',
  borderRadius: 30
};

function Pin({size = 40, onClick}: {size?: number; onClick?: () => void}) {
  return (
    <img width={size} src={logo} style={pinStyle} onClick={onClick} alt='' />
  );
}

export default React.memo(Pin);
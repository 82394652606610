import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserData } from '../../actions';
import { getShops, getUser, updateUser } from '../../API';
import useGuard from '../../hooks/useGuard';
import Shops from './Shops';

// import Shops from './Shops';
import Pin from './Pin';
import { t } from '../../services/i18n';

import mapboxgl from 'mapbox-gl';
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const TOKEN = "pk.eyJ1IjoibW1wZ3NvZnR3YXJlcyIsImEiOiJja2kxYnV2bnAwN3JkMnBxbmwwZGQxYm5hIn0.3A9baNUVkmkoqI0vCUp9pw";

export default function ShopSelection() {
  const [selectedShop, setSelectedShop] = useState<any>(null);
  const { userData: { addresses } } = useSelector((state: any) => state.UserReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useGuard();

  const { isLoading, data: shops } = useQuery('shops', async () => {
    const shopsRes = await getShops();
    const shops: any[] = shopsRes.data;
    if (shops.length) {
      console.log(shops);
      const filteredShops = shops
        .filter((shop) => shop.can_ecom === true && shop.can_delivery === true)
        .map((shop, index) => ({
          id: shop.id?.trim(),
          name: shop.name_en,
          addressDetails: shop.addressDetails,
        }))
        .sort((a, b) => a.addressDetails.City.localeCompare(b.addressDetails.City));

      filteredShops[0].addressDetails.Geolocation.lat = 33.573250

      return filteredShops;
    }
    return [];
  })

  const handleShopPick = async () => {
    const result = await updateUser({ Shop_id: selectedShop.id });
    if (result.success === true) {
      const userInfo = await getUser();
      if (userInfo.error === false) {
        userInfo.data.user.uid = userInfo?.data?.user?.id;
        userInfo.data.user.hasAddress = userInfo?.hasAddress;
        userInfo.data.user.addresses = userInfo?.data?.address;
        userInfo.data.user.shop = userInfo?.data?.shop;
        userInfo.data.user.subscription = userInfo?.data?.subscription;
        dispatch(setUserData(userInfo?.data?.user, true));
        navigate('/');
      }
    }
  };

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 64px)' }}>
      <Map
        initialViewState={{
          latitude: addresses[0].Geolocation.lat,
          longitude: addresses[0].Geolocation.long,
          zoom: 12.9,
          bearing: 0,
          pitch: 0
        }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={TOKEN}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        {
          shops?.map((shop) => (
            <Marker
              key={shop.id}
              longitude={Number(shop.addressDetails.Geolocation.long)}
              latitude={Number(shop.addressDetails.Geolocation.lat)}
              anchor="bottom"
            >
              <Stack alignItems='center' gap={1}>
                <Pin onClick={() => setSelectedShop(shop)} />
                <Typography sx={{ mb: 1 }}>{shop.name}</Typography>
              </Stack>
            </Marker>
          ))
        }

        {selectedShop && (
          <Popup
            anchor="top"
            longitude={Number(selectedShop.addressDetails.Geolocation.long)}
            latitude={Number(selectedShop.addressDetails.Geolocation.lat)}
            closeOnClick={false}
            onClose={() => setSelectedShop(null)}
          >
            <Typography sx={{ mb: 1 }}>{selectedShop.name}</Typography>
            <Button 
              sx={{ mt: 1 }} fullWidth size='small' variant='outlined'
              onClick={() => handleShopPick()}
            >
              {t('common:Continue')}
            </Button>
          </Popup>
        )}
      </Map>

      <Shops 
        selectedShop={selectedShop} setSelectedShop={setSelectedShop}
        isLoading={isLoading} 
        shops={shops}
        handleShopPick={handleShopPick}
      />
    </div>
  );
}
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, updateCart } from "../actions";
import i18n from "../services/i18n";
import { isEmpty } from "../utils";

export default function Item({ item }: any) {
  const { userData } = useSelector((state: any) => state.UserReducer);
  const { cartCount, cartItems, totalPrice } = useSelector(
    (state: any) => state.LayoutReducer
  );

  const itemIndex = cartItems.findIndex(
    (cartItem: any) => cartItem.Id === item.Id
  );
  const quantity = cartItems[itemIndex]?.quantity || 0;

  const dispatch = useDispatch();

  const getCartItemSum = (items: any) => {
    let sum = 0;
    items.forEach((item: any) => {
      sum += item.quantity;
    });
    return sum;
  };

  const checkProductNormal = (count: any) => {
    let cart = JSON.parse(JSON.stringify(cartItems));
    const foundIndex = cart.findIndex((vendor: any) => vendor.Id === item.Id);

    if ((quantity === 1 && count < 0) || (quantity === 0 && count < 0)) {
      if (foundIndex !== -1) {
        let newPrice =
          totalPrice -
          parseInt(cart[foundIndex].Price) * cart[foundIndex].quantity;
        cart.splice(foundIndex, 1);
        let Totalcount = cartCount === 1 ? 0 : cartCount - 1;
        dispatch(updateCart(cart, Totalcount, newPrice));
        // setQuantity(0);
      }
      return;
    }

    const newTotalQuantity = quantity + parseInt(count);
    // check if product id already in the cart
    if (foundIndex !== -1) {
      let newPrice =
        totalPrice -
        parseInt(cart[foundIndex].Price) * cart[foundIndex].quantity;
      newPrice = newPrice + newTotalQuantity * parseInt(cart[foundIndex].Price);
      cart[foundIndex].quantity = newTotalQuantity;
      const itemSum = getCartItemSum(cart);
      dispatch(updateCart(cart, itemSum, newPrice));
      // setQuantity(newTotalQuantity);
    } else {
      // Add item to the cart
      let cart = JSON.parse(JSON.stringify(cartItems));
      item.quantity = newTotalQuantity;
      item.fromSubscription = false;
      item.type = "order";
      cart.push(item);
      let total = totalPrice + parseInt(item.Price) * newTotalQuantity;
      const itemSum = getCartItemSum(cart);
      dispatch(updateCart(cart, itemSum, parseInt(total)));
    }
  };

  const updateQuantity = (count: any) => {
    let cart = JSON.parse(JSON.stringify(cartItems));
    const foundIndex = cart.findIndex((vendor: any) => vendor.Id === item.Id);
    let foundIndexInConsumedProducts = [];
    let foundIndexInSubscriptionProducts = [];
    if (userData && userData.subscription) {
      if (userData.subscription.consumedProducts) {
        foundIndexInConsumedProducts =
          userData.subscription.consumedProducts.findIndex(
            (vendor: any) => vendor.ProductId === item.Id
          );
      }
      if (userData.subscription.Products) {
        foundIndexInSubscriptionProducts =
          userData.subscription.Products.findIndex(
            (vendor: any) => vendor.ProductId === item.Id
          );
      }
    }
    // return () => {
    if (cartItems.length > 0) {
      if (cartItems[0]?.type === "subscription") {
        dispatch(
          showAlert(
            "warning",
            "The cart contains products from type " + cartItems[0]?.type
          )
        );
        return;
      }
    }

    if (
      userData.subscription &&
      !isEmpty(userData.subscription) &&
      userData.subscription.isActive
    ) {
      // the products is not in consumed products we need to check if it exist in subscription products
      // check if the products is in subscription products
      if (foundIndexInSubscriptionProducts !== -1) {
        if ((quantity === 1 && count < 0) || (quantity === 0 && count < 0)) {
          // here check if the product is in the cart
          if (foundIndex !== -1) {
            let SubscriptionProduct =
              userData.subscription?.Products[foundIndexInSubscriptionProducts];
            let usedProduct =
              userData.subscription?.consumedProducts[
                foundIndexInConsumedProducts
              ];
            let quant =
              0 -
              (SubscriptionProduct.Quantity -
                (foundIndexInConsumedProducts !== -1
                  ? usedProduct.usedQuantity
                  : 0));
            let newPrice =
              totalPrice - (quant <= 0 ? 0 : cart[foundIndex].Price);

            cart.splice(foundIndex, 1);
            let Totalcount = cartCount === 1 ? 0 : cartCount - 1;
            dispatch(updateCart(cart, Totalcount, newPrice));
            // setQuantity(0);
          }
          return;
        }

        const newTotalQuantity = quantity + parseInt(count);
        // check if product id already in the cart
        if (foundIndex !== -1) {
          let SubscriptionProduct =
            userData.subscription?.Products[foundIndexInSubscriptionProducts];
          let usedProduct =
            userData.subscription?.consumedProducts[
              foundIndexInConsumedProducts
            ];
          let quant =
            newTotalQuantity -
            (SubscriptionProduct.Quantity -
              (foundIndexInConsumedProducts !== -1
                ? usedProduct.usedQuantity
                : 0));
          let newPrice = totalPrice;

          if (newPrice === 0) {
            newPrice =
              quant <= 0
                ? 0
                : newPrice + quant * parseInt(cart[foundIndex].Price);
          } else {
            // here means the count is ( -1 )  so it's -
            if (count === -1) {
              newPrice =
                quant < 0
                  ? newPrice
                  : newPrice - parseInt(cart[foundIndex].Price);
            } else {
              // here the count is ( 1 ) so it's +

              newPrice =
                quant <= 0
                  ? newPrice
                  : newPrice + parseInt(cart[foundIndex].Price);
            }
          }

          cart[foundIndex].quantity = newTotalQuantity;
          cart[foundIndex].fromSubscription = true;
          const itemSum = getCartItemSum(cart);
          dispatch(updateCart(cart, itemSum, parseInt(newPrice)));
          // setQuantity(newTotalQuantity);
        } else {
          // here the product is not in the cart but it is in the subscription
          // Add item to the cart

          let cart = JSON.parse(JSON.stringify(cartItems));
          let SubscriptionProduct =
            userData.subscription?.Products[foundIndexInSubscriptionProducts];
          let usedProduct =
            userData.subscription?.consumedProducts[
              foundIndexInConsumedProducts
            ];
          let quant =
            newTotalQuantity -
            (SubscriptionProduct.Quantity -
              (foundIndexInConsumedProducts !== -1
                ? usedProduct.usedQuantity
                : 0));
          let newPrice = totalPrice;

          if (newPrice === 0) {
            newPrice = quant <= 0 ? 0 : newPrice + quant * parseInt(item.Price);
          } else {
            // here means the count is ( -1 )  so it's -
            if (count === -1) {
              newPrice = quant < 0 ? newPrice : newPrice - parseInt(item.Price);
            } else {
              // here the count is ( 1 ) so it's +

              newPrice =
                quant <= 0 ? newPrice : newPrice + parseInt(item.Price);
            }
          }
          item.quantity = newTotalQuantity;
          item.fromSubscription = true;
          item.type = "order";
          cart.push(item);

          const itemSum = getCartItemSum(cart);
          dispatch(updateCart(cart, itemSum, parseInt(newPrice)));
        }

        return;
      }
    }
    checkProductNormal(count);
    // };
  };

  return (
    <Card elevation={0} sx={{ backgroundColor: "white" }}>
      <CardContent sx={{ p: "0 !important" }}>
        <Grid container padding={2} width="100%" gap={3}>
          <Grid item>
            <Avatar sx={{ width: 100, height: 100 }} src={item.Images[0]} />
          </Grid>

          <Grid
            item
            flex={1}
            container
            flexDirection="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h6">
                {item[`Name_${i18n.locale}`]}
              </Typography>
              <Typography color="GrayText" variant="body1">
                {item.Price} dhs{item.Unit === "m2" ? "/m2" : ""}
              </Typography>
            </Grid>

            <Grid container justifyContent="end" alignItems="center" gap={1}>
              <IconButton
                onClick={() => updateQuantity(-1)}
                sx={{ p: 0 }}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <RemoveRounded />
              </IconButton>

              <Typography color="GrayText" variant="body1">
                {quantity}
              </Typography>

              <IconButton
                onClick={() => updateQuantity(1)}
                sx={{ p: 0 }}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AddRounded />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

import { EmailOutlined, PasswordRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, InputAdornment, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getApp } from 'firebase/app';
import { getAuth, getIdToken } from 'firebase/auth';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { setUserData } from '../actions';
import { getUser } from '../API';
import { signIn } from '../API/firebaseMethods';
import ForgotPasswordDialog from '../components/ForgotPasswodDialog';
import SignUpMethods from '../components/SignUpMethods';
import logo from '../images/logo.png';
import { t } from '../services/i18n';




export default function Login() {
  const navigate = useNavigate();
  const userState = useSelector((state: any) => state.UserReducer);
  const dispatch = useDispatch();

  const { loading } = useSelector((state: any) => state.LayoutReducer);

  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  const { values, errors, touched, handleSubmit, handleChange, isSubmitting } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t("common:Email_invalid_please_use_the_correct_format"))
        .required(t("common:Email_field_is_required")),
      password: yup
        .string()
        .required(t("common:Password_field_is_required"))
    }),
    async onSubmit({ email, password }) {
      let res = await signIn(email, password);
      
      if (res?.user) {
        const firebaseapp = getApp();
        const user = getAuth(firebaseapp);
        if (user.currentUser) {
          const token = await getIdToken(user.currentUser, true);
          localStorage.setItem('token', token);

          const userInfo = await getUser();
          if (userInfo.error === false) {
            userInfo.data.user.uid = res.user.uid;
            userInfo.data.user.hasAddress = userInfo?.hasAddress;
            userInfo.data.user.addresses = userInfo?.data?.address;
            userInfo.data.user.shop = userInfo?.data?.shop;
            dispatch(setUserData(userInfo?.data?.user, true));
            navigate('/');
          }
        }
      }
    },
  });
  
  useEffect(() => {
    if (userState.isLoggedIn) {
      navigate("/");
    }
  }, [userState, navigate]);

  return (
    loading ? (
      <Box sx={{ height: '100vh' }}>
        <Stack sx={{ width: '100%', height: '100%' }} justifyContent='center' alignItems='center'>
          <CircularProgress size={80} />
        </Stack>
      </Box>
    ) : (
      <Container component="main" maxWidth="xs" sx={{ pt: 8 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} width={100} alt='' />
          <Typography component="h1" marginTop={3} variant="h5">
            {t("common:Sign_in_to_your_account")} <br />
            {/* password123 */}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth margin="normal" color='primary'
                name="email" label={t("common:Your_email")}
                placeholder={t("common:email_placeholder")}
                autoFocus
                value={values.email} onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
                />
        
              <TextField
                fullWidth margin="normal" color='primary'
                name="password" label={t("common:Password")}
                type="password"
                placeholder='*********'
                value={values.password} onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordRounded />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <LoadingButton 
                fullWidth
                color='primary'
                sx={{ mt: 1, mb: 2 }}
                loading={isSubmitting}
                type="submit" 
                variant='contained' 
                disableElevation
              >
                {t("common:Save")}
              </LoadingButton>

              <Link 
                onClick={() => setOpenForgotPassword(true)}
                href="#" variant="body2" color="secondary"
                textAlign='center'
                sx={{ cursor: 'pointer', display: 'block', mb: 1 }}
              >
                {t("common:Forgot_Password")}
              </Link>
              <Link 
                variant="body2" color='secondary' 
                textAlign='center'
                sx={{ cursor: 'pointer', display: 'block' }}
                onClick={() => navigate('/register')}
              >
                {t("common:Dont_have_an_account")} {t("common:Create_one")}
              </Link>

              <SignUpMethods />
            </form>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 3, mb: 4 }}>
          {t("common:Copyright_cleanzy", { year: new Date().getFullYear() })}
        </Typography>
          
        <ForgotPasswordDialog open={openForgotPassword} handleClose={() => setOpenForgotPassword(false)} />
      </Container>
    )
  );
}
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useState } from "react";
import { t } from "../services/i18n";
import AdditionalServicesSelect from "./AdditionalServicesSelect";

export default function AdditionalServicesModal({ 
  open, handleClose, 
  additionalServices,
  handleSave, 
  specialServicesUsed
}: any) {
  const [specialServicesIds, setSpecialServicesIds] = useState(specialServicesUsed || []);

  function handleSaveAdditionalServices() {
    handleSave(specialServicesIds)
  }

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>{t("common:additional_services_title")}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t("common:additional_services_body")}
        </DialogContentText>
        <AdditionalServicesSelect 
          additionalServices={additionalServices} 
          specialServicesIds={specialServicesIds}
          setSpecialServicesIds={setSpecialServicesIds}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common:Cancel")}</Button>
        <Button 
          onClick={handleSaveAdditionalServices} 
          variant='contained' 
          disableElevation
        >
          {t("common:Save")}
        </Button>
      </DialogActions>
		</Dialog>
	);
}

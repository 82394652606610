import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, TextField
} from "@mui/material";
import { useFormik } from "formik";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { showAlert } from "../actions";
import { addComplaint } from "../API";
import i18n, { t } from "../services/i18n";
import { ComplaintModalContext } from "./AppBar";

export default function ComplaintModal() {
  const { openComplaintModal, setOpenComplaintModal } = useContext(ComplaintModalContext);
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.UserReducer);

  const { values, errors, touched, handleSubmit, handleChange, resetForm, isSubmitting } = useFormik({
    initialValues: {
      subject: "",
      complaint: "",
    },
    validationSchema: yup.object({
      subject: yup
        .string()
        .required(t("common:all_fields_are_required_sing")),
      complaint: yup
        .string()
        .required(t("common:all_fields_are_required_sing")),
    }),
    async onSubmit({ subject, complaint }) {
      let values = {
        title: subject,
        description: complaint,
        HandleBy: userData.uid,
        Date: new Date(),
        Status: "Ongoing",
      };
      let res = await addComplaint(values);
      if (res.error === false) {
        dispatch(showAlert('success', t("common:complaint_sent_successfully")));
        resetForm();
        setOpenComplaintModal(false)
      } else {
        dispatch(showAlert('error', res.message || res.error));
      }
    }
  });

	return (
		<Dialog open={openComplaintModal} onClose={() => setOpenComplaintModal(false)}>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ textAlign: 'left' }}>
          {t("common:new_complaint")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText textAlign='left'>
            {t("common:Please_fill_all_required_fields_to_add_a_new_complaint")}
          </DialogContentText>
          <TextField
            dir={i18n.dir}
            fullWidth margin="normal" color='primary' 
            name="subject" label={t("common:Title_complaint")}
            autoFocus
            value={values.subject} onChange={handleChange}
            error={touched.subject && Boolean(errors.subject)}
            helperText={touched.subject && errors.subject}
          />

          <TextField
            dir={i18n.dir}
            fullWidth margin="normal" color='primary' 
            name="complaint" label={t("common:body_complaint")}
            multiline
            rows={3}
            value={values.complaint} onChange={handleChange}
            error={touched.complaint && Boolean(errors.complaint)}
            helperText={touched.complaint && errors.complaint}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenComplaintModal(false)}>{t("common:Cancel")}</Button>
          <LoadingButton 
            loading={isSubmitting}
            type="submit" 
            variant='contained' 
            disableElevation
          >
            {t("common:Save")}
          </LoadingButton>
        </DialogActions>
      </form>
		</Dialog>
	);
}

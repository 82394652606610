import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, showAlert } from "../../actions";
import { updateUserAvatar } from "../../API";
import { t } from "../../services/i18n";
import { isEmpty } from "../../utils";

export default function AvatarPreview({ open, handleClose, handleCloseCrop, image }: any) {
  const { userData } = useSelector((state: any) => state.UserReducer);
  const dispatch = useDispatch();

	async function handlePhotoSave() {
    handleClose();
		handleCloseCrop();

    if (isEmpty(image)) {
      dispatch(showAlert('error', t("common:please_choose_an_image_first")));
      return;
    }
    let user = JSON.parse(JSON.stringify(userData));
    user.avatar = image;
    dispatch(setUserData(user, true));
    dispatch(showAlert('success', t("common:your_image_changed_successfully")));
    const response = await fetch(image);
    const blob = await response.blob();
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp);

    const userRef = ref(storage, "users/" + userData.uid);
    const snapshot = await uploadBytes(userRef, blob);

    const uploadUrl = await getDownloadURL(snapshot.ref);

    let res = await updateUserAvatar(uploadUrl);

    if (res.error === true) {
      dispatch(showAlert('error', res.message || res.error));
    }
  }

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Avatar 
          // onClick={handlePickPhoto}
          sx={{ width: 200, height: 200, mx: 'auto' }} 
          src={image} 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common:Re-take")}</Button>
        <Button 
					onClick={handlePhotoSave} 
					variant='contained' 
					disableElevation
				>
          {t("common:Save")}
        </Button>
      </DialogActions>
		</Dialog>
	);
}

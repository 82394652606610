import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Container, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { showAlert } from "../../actions";
import { getAvailableHours } from "../../API";
import { t } from "../../services/i18n";
import { formatDate } from "../../utils";

export default function CleanzyDelivery() {
  const { userData: { shop, Shop_id, addresses } } = useSelector((state: any) => state.UserReducer);
  const { cartItems } = useSelector((state: any) => state.LayoutReducer);
  const isFromShop = cartItems[0]?.type !== "shop";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [hours, setHours] = useState<any>([]);
  const [deliveryType, setDeliveryType] = useState('Classique_Delivery');

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      address: "",
      date: "",
      hour: "",
    },
    validationSchema: yup.object({
      address: yup
        .string()
        .required(t("common:Please_choose_a_Pickup_Address_first")),
      date: yup
        .string()
        .required(t("common:Please_choose_a_Pickup_date_first")),
      hour: yup
        .string()
        .required(t("common:Please_choose_a_Pickup_time_first")),
    }),
    async onSubmit({ address, date, hour }) {
      let values = {
        selectedPickupAddress: address,
        selectedPickupDate: date,
        delivery_type: deliveryType,
        selectedPickupTime: hour,
      };
      navigate('/payment', { state: { values, isFromShop, from: 'cleanzyDelivery' } })
      // const result = await updateUserPhoneNumber({ 
      //   phoneNumber: phone, 
      //   countryCode, 
      //   name 
      // })
      // if (result.success === true) {
      //   dispatch(setUserPhoneAndName({
      //     PhoneNumber: phone,
      //     CountryCode: countryCode,
      //     Name: name
      //   }))
      //   dispatch(showAlert('success', 'Informations updated !'));
      //   handleClose();
      //   resetForm();
      // } else {
      //   dispatch(showAlert("error", "There is something wrong !"));
      // }
    }
  });

  async function handleChangeDate(date: any) {
    setFieldValue('date', formatDate(date));
    setFieldValue('hour', '');

    let res = await getAvailableHours({
      shop: Shop_id,
      date: formatDate(date),
      hour: new Date().getHours(),
    });

    if (res.error === false) {
      if (res.data !== null) {
        const newHours = [];
        for (const hours of res.data) {
          const time = hours.time.toString();
          if (time) {
            let hour = +time.substring(0, 2);
            hour += 1;
            newHours.push({
              isReserved: hours.isReserved,
              time: `${time}-${hour}:00`,
            });
          }
        }
        setFieldValue('date', formatDate(date));
        setHours(newHours);
      }
    } else {
      if (res.message === "No schedule for this shop") {
        dispatch(showAlert('error', t("common:no_schedule_available")));
      } else if (res.message === "No schedule for this day") {
        dispatch(showAlert('error', t("common:no_schedule_available_day")));
      } else {
        dispatch(showAlert('error', t("common:There_is_some_problem_getting_the_time_data_try_again")));
      }
      return;
    }
  }

  return (
    <Container maxWidth='sm' sx={{ pt: 5 }}>
      <Typography variant="h4">{t("common:boutique_traitante")}</Typography>
      <Typography>
        {shop?.Name_fr} <br />
        {shop.shopAddress?.Road} {shop.shopAddress?.Number} <br />
        {shop.shopAddress?.PostalCcode} {shop.shopAddress?.City} <br />
        Tel: {shop?.PhoneNumber}
      </Typography>

      <Divider sx={{ my: 3 }}></Divider>

      <Typography variant="h4">{t("common:Pickup")}</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>{t("common:Choose_address")}</InputLabel>
          <Select
            name='address' label={t("common:Choose_address")}
            value={values.address} onChange={handleChange}
            error={touched.address && Boolean(errors.address)}
          >
            {
              addresses?.map((address: any) => (
                <MenuItem value={address.id} key={address.id}>{address.FirstLine}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <Stack direction='row' gap={1}>
          <Box sx={{ mt: 2, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t("common:Choose_date")}
                value={values.date}
                onChange={handleChangeDate}
                renderInput={(params: any) => (
                  <TextField 
                    {...params} 
                    name='date'
                    error={touched.date && Boolean(errors.date)}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <FormControl fullWidth sx={{ mt: 2, width: '50%' }}>
            <InputLabel>{t("common:Choose_time")}</InputLabel>
            <Select
              name="hour" label={t("common:Choose_time")}
              value={values.hour} onChange={handleChange}
              error={touched.hour && Boolean(errors.hour)}
            >
              {
                hours.map((hour: any) => (
                  <MenuItem value={hour.time} disabled={hour.isReserved} key={hour.time}>
                    {hour.time}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Stack>

        <FormControl>
          <RadioGroup
            defaultValue="female"
            value={deliveryType}
            onChange={(e) => setDeliveryType(e.target.value)}
          >
            <Stack direction='row' sx={{ mt: 2 }}>
              <FormControlLabel value="Classique_Delivery" control={<Radio />} label="" />
              <Box sx={{ cursor: 'pointer' }} onClick={() => setDeliveryType('Classique_Delivery')}>
                <Box>{t("Classique_Delivery")}</Box>
                <Box>
                  <Typography color='GrayText'>
                    {t("common:Classique_Delivery_note")}
                  </Typography>
                </Box>
              </Box>
            </Stack>

            <Stack direction='row' sx={{ mt: 2 }}>
              <FormControlLabel value="Express_Delivery" control={<Radio />} label="" />
              <Box sx={{ cursor: 'pointer' }} onClick={() => setDeliveryType('Express_Delivery')}>
                <Box>{t("common:Express_Delivery")}</Box>
                <Box>
                  <Typography color='GrayText'>
                    {t("common:Express_Delivery_note")}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </RadioGroup>
        </FormControl>
        
        <Button
          fullWidth
          color='primary'
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disableElevation
          type='submit'
        >
          {t("common:To_Payement")}
        </Button>
      </form>
    </Container>
  )
}
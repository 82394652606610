import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, signInWithCredential, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { setUserData, showAlert } from "../actions";
import { createUser } from "../API";
import { userHasaddress } from "../API/firebaseMethods";
import apiKeys from "../config/keys";
import { t } from "../services/i18n";
import store from "../store";

const firebase = initializeApp(apiKeys.firebaseConfig);
const db = getFirestore(firebase);
const auth = getAuth();
auth.languageCode = 'fr';
  
export default function UseSignInWithFacebook() {  
  const navigate = useNavigate();
  return () => {
    const provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
    .then(async (result) => {
      // // The signed-in user info.
      // const facebookUser = result.user;
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      
      const resultAuth = await signInWithCredential(auth, credential)
      if (!resultAuth.user) {
        store.dispatch(showAlert(t("common:generic_error")));
        return;
      }
      const userColl = collection(db, 'users');
      const userDocRef = doc(userColl, resultAuth.user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        updateDoc(userDocRef, { 
          last_logged_in: Date.now(),
          Id: resultAuth.user.uid
        });
        navigate('/');
      } else {
        let values = {
          Name: resultAuth.user.displayName,
          uid: resultAuth.user.uid,
          Email: resultAuth.user.email,
          CountryCode: "+212",
          Creation_date: Date.now(),
          Phone: null,
          Id: resultAuth.user.uid,
        };
        let res = await createUser(values);
        if (res.error === false) {
          navigate('/address-selection');
        } else {
          store.dispatch(showAlert(res.message || res.error));
          return;
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
}

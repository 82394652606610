import { OpenInNew } from "@mui/icons-material";
import { Box, CircularProgress, Container, Divider, IconButton, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUserOrders } from "../../API";
import i18n, { t } from "../../services/i18n";
import { ucwords } from "../../utils";
import OrderDetailsModal from "./OrderDetailsModal";

export default function MyOrders() {
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);
  const [orderToShow, setOrderToShow] = useState<any>(null);
  
  const { userData } = useSelector((state: any) => state.UserReducer);

  const [search] = useSearchParams();
  const queryOrderId = search.get('orderId');

  const { isLoading, data: orders } = useQuery('orders', async () => {
    const res = await getUserOrders();
    const orders = res.error ? [] : res.data;
    const sortedOrders = orders.sort((a: any, b: any) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    console.log(sortedOrders);
    return sortedOrders;
  })

  useEffect(() => {
    if (queryOrderId && orders) {
      const queryOrder = orders.find((order: any) => order.id === queryOrderId);
      setOrderToShow(queryOrder);
      setOpenOrderDetailsModal(true);
    }
  }, [orders, queryOrderId])

  function handleOpenOrderDetailsModal(order: any) {
    setOrderToShow(order);
    setOpenOrderDetailsModal(true);
  }

  function title(order: any) {
    const createdAt = new Date(order.createdAt);
    const type = ucwords(t(`common:${order.products[0].type}`))
    const dateString = (
      order.createdAt ? 
        ` - ${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}` 
      : ''
    )
    return type + dateString;
  }

  function details(order: any) {
    if (order.products[0].type === "subscription") {
      return `${order.products[0][`Title_${i18n.locale}`]} ${order.products[0]["Duration"]} | ${Math.round(order?.total)} dhs `;
    } else {
      return `${t("common:Number_of_pieces")} ${order?.products?.length} | ${Math.round(order?.total)} dhs`;
    }
  }

  return (
    <Container maxWidth='xs' sx={{ height: '100vh', pt: 5 }}>
      <Typography variant="h4" marginBottom={2}>{t("common:My_Orders")}</Typography>
      {
        isLoading ? (
          <Box sx={{ mt: 5, textAlign: 'center' }}>
            <CircularProgress size={50} />
          </Box>
        ) : !orders.length ? (
          <Typography 
            variant="body2" color='secondary' 
            textAlign='center'
            sx={{ pt: 3, display: 'block' }}
          >
            {t('common:no_order_available')}
          </Typography>
        ) : (
          <List dense>
            {
              orders.map((order: any, i: number) => <>
                <ListItem
                  disablePadding
                  divider
                  sx={{ py: 1 }}
                  key={order.id}
                  secondaryAction={
                    <Stack direction='row' gap={1}>
                      <IconButton 
                        color="success"
                        edge="end" 
                        onClick={() => handleOpenOrderDetailsModal(order)}
                      >
                        <OpenInNew />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemText 
                    primaryTypographyProps={{fontSize: '17px'}} 
                    primary={title(order)}
                    secondary={details(order)}
                  />
                </ListItem>
                {i < userData.addresses.length - 1 && <Divider />}
              </>)
            }
          </List>
        )
      }

      {
        orderToShow &&
          <OrderDetailsModal 
            open={openOrderDetailsModal} 
            handleClose={() => setOpenOrderDetailsModal(false)} 
            order={orderToShow}
          />
      }
    </Container>
  )
}
import { Box, Button, CircularProgress, Container, Grid, Link, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCategoryProducts } from "../../actions";
import { categByType, productsByCateg } from "../../API/product-api";
import Item from "../../components/Item";
import RequestModal from "../../components/RequestModal";
import { t } from "../../services/i18n";

export default function Products({ type }: any) {
  const { userData: { shop } } = useSelector((state: any) => state.UserReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  const types = [
    { route: '/pressing', name: t("common:Order") },
    { route: '/subscriptions', name: t("common:Subscriptions") },
    { route: '/shop', name: t("common:Shop") },
  ]

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [openRequestModal, setOpenRequestModal] = useState(false);

  const { 
    isLoading: isLoadingCategories, 
    data: categories, 
  } = useQuery('categories', fetchCategories)

  async function fetchCategories() {
    const res = await categByType({ type });
    return res?.data;
  }

  const { 
    isLoading: isLoadingProducts, 
    isIdle: isIdleProducts, 
    data: products, 
  } = useQuery(['products', categories, activeCategoryIndex], fetchProducts, { enabled: !!categories });

  async function fetchProducts() {
    if (categories[0]) {
      const products = await getProducts({
        category: categories[activeCategoryIndex]?.cat_id || categories[0].cat_id,
        type: "pressing",
        shopAddressId: shop.Address,
        shopProducts: shop.ProductList,
        shopAddress: shop.shopAddress,
      });
      
      return products;
    }
  }
  
  async function getProducts(categId: any) {
    const result = await productsByCateg(categId);
    dispatch(setCategoryProducts(categId, result.data));
    return result.data;
  }

  return (
    <Container maxWidth='md' sx={{ height: '100vh' }}>
      <Stack spacing={2} direction="row" justifyContent='center' mt={3}>
        {
          types.map(type => (
            <Button 
              disableElevation
              variant={type.route === pathname ? 'contained' : 'outlined'} 
              color={type.route === pathname ? 'primary' : 'secondary'}
              onClick={() => navigate(type.route)} 
              key={type.route}
            >
              {type.name}
            </Button>
          ))
        }
      </Stack>

      {
        isLoadingCategories ? (
          <Box sx={{ mt: 5, textAlign: 'center' }}>
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid container marginTop={4} rowSpacing={3} columnSpacing={3}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                  value={activeCategoryIndex} 
                  onChange={(e: any, newValue: number) => setActiveCategoryIndex(newValue)} 
                  aria-label="basic tabs example"
                >
                  {
                    ((categories || []) as any[]).map(({ category }: any, i: number) => (
                      <Tab label={category} key={category.cat_id} {...{
                        id: `simple-tab-${i}`,
                        'aria-controls': `simple-tabpanel-${i}`,
                      }} />
                    ))
                  }
                </Tabs>
              </Box>
            </Box>
          </Grid>
        )
      }

      {
        isLoadingProducts ? (
          <Box sx={{ mt: 7, textAlign: 'center' }}>
            <CircularProgress size={50} />
          </Box>
        ) : !isIdleProducts && <>
          {
            products?.length ? (
              <Grid container marginTop={2} rowSpacing={3} columnSpacing={3}>
                {((products || []) as any[]).map((product: any, i: number) => (
                  <Grid item xs={6} key={i}>
                    <Item item={product} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography 
                variant="body2" color='secondary' 
                textAlign='center'
                sx={{ cursor: 'pointer', pt: 3, display: 'block' }}
                onClick={() => setOpenRequestModal(true)}
              >
                {t("common:noProductsAvailable")}
              </Typography> 
            )
          }

          <Link 
            variant="body2" color='secondary' 
            textAlign='center'
            sx={{ cursor: 'pointer', pt: 3, display: 'block' }}
            onClick={() => setOpenRequestModal(true)}
          >
            {t("common:you_did_not_find_what_you_wished_for")}
          </Link> 

          <RequestModal 
            open={openRequestModal}
            handleClose={() => setOpenRequestModal(false)}
          />
        </>
      }
    </Container>
  )
}
import { DeleteOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Drawer, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "../../services/i18n";
import { isEmpty } from "../../utils";
import CartItem from "./CartItem";
import ClearCartModal from "./ClearCartModal";
import PhoneAndNameModal from "./PhoneAndNameModal";

const clearCartButtonStyle = {
  position: 'absolute',
  top: 25, right: 10
}

export default function CartDrawer({ show, toggleDrawer }: any) {
  const { cartItems, totalPrice } = useSelector((state: any) => state.LayoutReducer);
  const { userData } = useSelector((state: any) => state.UserReducer);

  const navigate = useNavigate();

  const [showConfirmClearCart, setShowConfirmClearCart] = useState(false);
  const [showPhoneAndNameModal, setShowPhoneAndNameModal] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [sumAdditional, setsumAdditional] = useState(0);

  useEffect(() => {
    let addionalTotal = 0;
    if (
      cartItems[0]?.type !== "shop" &&
      cartItems[0]?.type !== "subscription"
    ) {
      addionalTotal = cartItems.reduce((ac: any, value: any) => {
        return ac + (value.sumAdditional * value.quantity || 0)
      }, 0);
      setsumAdditional(addionalTotal);
    }

    if (!isEmpty(cartItems) && cartItems[0]?.type === "subscription") {
      setDeliveryFee(0);
    } else {
      if (totalPrice + addionalTotal < 150) {
        setDeliveryFee(20);
      } else {
        setDeliveryFee(0);
      }
    }
  }, [cartItems, totalPrice])

  function handleCheckout() {
    if (!userData.Phone || !userData.Name) {
      setShowPhoneAndNameModal(true);
    } else if (!isEmpty(cartItems[0]) && cartItems[0]?.type === "subscription") {
      navigate("/payment", { state: {
        //   from: fromScreen,
        //   values,
        //   selectedDeliveryAddress,
        //   isFromShop,
        //   sumAdditional,
      }})
      toggleDrawer(false);
    } else {
      navigate("/delivery-method", { state: {
      //   from: fromScreen,
      //   values,
      //   isFromShop,
      //   sumAdditional,
      }})
      toggleDrawer(false);
    }
  }

  return (
    <Drawer
      anchor={'right'}
      open={show}
      onClose={() => toggleDrawer(false)}
    >
      <Paper sx={{ backgroundColor: '#f8f8f8', px: 2, height: '100%' }}>
        <Typography variant='h4' align='center' sx={{ mt: 3, mb: 2 }}>
          Cart
        </Typography>

        <IconButton
          onClick={() => setShowConfirmClearCart(true)}
          color="primary" sx={clearCartButtonStyle}
        >
          <DeleteOutlined />
        </IconButton>

        <Divider />
        {
          cartItems.map((item: any, i: number) => (
            <CartItem item={item} index={i} key={item.id} />
          ))
        }
        <Divider sx={{ my: 2 }} />

        <Typography variant='h6'>{t("common:price_summary")}</Typography>
        <Typography color='GrayText'>
          <Stack direction='row' justifyContent='space-between'>
            <Box>{t("common:Price")}</Box>
            <Box>{totalPrice} dhs</Box>
          </Stack>
          {
            cartItems[0]?.type === 'order' && (
              <Stack direction='row' justifyContent='space-between'>
                <Box>{t("common:Additional_services")}</Box>
                <Box>{sumAdditional} dhs</Box>
              </Stack>
            )
          }
          <Stack direction='row' justifyContent='space-between'>
            <Box>{t("common:Delivery_Fee")}</Box>
            <Box>{deliveryFee !== 0 ? (20 + ' dhs') : t("common:free_delivery")}</Box>
          </Stack>
          <Stack direction='row' justifyContent='space-between'>
            <Box>{t("common:Total")}</Box>
            <Box>{sumAdditional + deliveryFee + totalPrice} dhs</Box>
          </Stack>
        </Typography>

        <Button 
          sx={{ mt: 2 }}
          onClick={handleCheckout} 
          disableElevation
          fullWidth
          variant='contained'
        >
          {t("common:CHECKOUT")}
        </Button>
      </Paper>

      <ClearCartModal 
        open={showConfirmClearCart}
        handleClose={() => setShowConfirmClearCart(false)}
      />

      <PhoneAndNameModal 
        open={showPhoneAndNameModal}
        handleClose={() => setShowPhoneAndNameModal(false)}
      />
    </Drawer>
  )
}
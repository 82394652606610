import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useRef, useState } from "react";
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { t } from "../../services/i18n";
import AvatarPreview from "./AvatarPreview";

export default function AvatarCrop({ open, handleClose, src }: any) {

  const [crop, setCrop] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<any>(null);
  const [openAvatarPreview, setOpenAvatarPreview] = useState(false);

  const imageRef = useRef<any>(null);

  async function makeClientCrop(crop: any) {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageUrl(croppedImageUrl);
      setOpenAvatarPreview(true)
    }
  }

  function getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx: any = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          const fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>{t("common:Crop_image_title")}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          {t('common:Crop_image_description')}<br />
        </DialogContentText>
        <ReactCrop 
          crop={crop} 
          onChange={c => setCrop(c)} 
          onComplete={makeClientCrop}
          aspect={1}
        >
          <img src={src} ref={imageRef} alt='' />
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common:Cancel")}</Button>
      </DialogActions>

      <AvatarPreview 
				image={croppedImageUrl}
				open={openAvatarPreview} 
				handleClose={() => setOpenAvatarPreview(false)} 
				handleCloseCrop={handleClose} 
			/>
		</Dialog>
	);
}

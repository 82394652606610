import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../actions";
import i18n, { t } from "../../services/i18n";

export default function AddSubToCartConfirmation({ open, handleClose, subscription }: any) {
	const { cartItems, totalPrice, cartCount } = useSelector((state: any) => state.LayoutReducer);
	const dispatch = useDispatch();

	function handleAddSubToCart() {
    let cart = JSON.parse(JSON.stringify(cartItems));
    subscription.type = "subscription";
    subscription.quantity = 1;
    cart.push(subscription);
    let total = totalPrice + parseInt(subscription.Price);
    dispatch(updateCart(cart, cartCount + 1, parseInt(total)));
    handleClose();
  }

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>{t('common:Confirmation')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t("common:Would_you_like_to_add_to_your_cart", {
            name:
              subscription[`Title_${i18n.locale}`] +
              " " +
              t(`common:${subscription["Duration"]}`)
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common:Cancel")}</Button>
        <Button onClick={handleAddSubToCart} variant='contained' disableElevation>{t("common:Yes")}</Button>
      </DialogActions>
		</Dialog>
	);
}

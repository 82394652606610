import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { t } from "../../services/i18n";

export default function ClearCartModal({ open, handleClose }: any) {
	const dispatch = useDispatch();

	function handleClearCart() {
		dispatch(updateCart([], 0, 0));
		handleClose();
	}

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>
				{t("common:Confirmation")}
			</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t("common:Are_you_sure_you_want_to_empty_the_cart")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained' disableElevation>{t("common:Cancel")}</Button>
        <Button onClick={handleClearCart}>{t("common:Yes")}</Button>
      </DialogActions>
		</Dialog>
	);
}

import { Box, CircularProgress, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useGuard from '../hooks/useGuard';
import AppBar from './AppBar';

export default function Layout({ children }: { children: any }) {
  const { pathname } = useLocation();
  const { loading } = useSelector((state: any) => state.LayoutReducer);

  useGuard();

  return (
    loading ? (
      <Box sx={{ height: '100vh' }}>
        <Stack sx={{ width: '100%', height: '100%' }} justifyContent='center' alignItems='center'>
          <CircularProgress size={80} />
        </Stack>
      </Box>
    ) : <>
      {!['/login', '/register'].includes(pathname) && <AppBar />}
      {children}
    </>
  )
}
import actionTypes from "../actions/types";

const initialState = {
  title: null,
  body: null,
  opened: false,
  type: null,
};

const OrderReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case actionTypes.PAYMENT_ORDER_DISPLAYED:
      const payload = action.payload;
      return {
        ...state,
        title: payload.title,
        body: payload.body,
        type: payload.type,
        opened: true,
      };
    case actionTypes.CLOSE_PAYMENT_ORDER_DISPLAY:
      return {
        ...state,
        opened: false,
      };
    case actionTypes.PAYMENT_ORDER_MODAL_RESET_VALUES:
      return {
        ...state,
        title: null,
        body: null,
        type: null,
      };
    default:
      return state;
  }
};

export default OrderReducer;

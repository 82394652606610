import { Public } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import locales from "../../data/locales";

export default function LocaleMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  async function handleChangeLocale(locale: string) {
    i18next.changeLanguage(locale);
    localStorage.setItem("locale", locale);
    window.location.reload();
  }

  function handleMouseEnter(e: any) {
    e.target.style.backgroundColor= '#53b3a6';
    e.target.style.color= 'white';
  }

  function handleMouseLeave(e: any, isSelected: boolean) {
    if (!isSelected) {
      e.target.style.backgroundColor = 'white';
      e.target.style.color = 'black';
    }
  }

  return <>
    <Stack direction='row' alignItems='center' gap={1} paddingX={1}>
      <Typography 
        variant="body1" alignItems='center' 
        sx={{ cursor: 'pointer', color: 'white' }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        >
        {localStorage.locale}
      </Typography>
    </Stack>

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      
    >
      {
        locales.map(locale => (
          <MenuItem 
            selected={locale.short === localStorage.locale}
            onClick={() => handleChangeLocale(locale.short)} 
            style={
              locale.short === localStorage.locale ?
                { backgroundColor: '#53B3A6', color: 'white' } 
              : 
                {}
            }
            // classes={{ selected: "selectedMenuItem"}}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={(e: any) => handleMouseLeave(e, locale.short === localStorage.locale)}
            key={locale.short}
          >
            {locale.long}
          </MenuItem>
        ))
      }
    </Menu>
  </>
}
import api from "../config/keys";
import store from "../store";

const prefix = 'product';

// /**
//  * Get the categories accoridng to the type selected
//  * @param {string} type The type to get the categories from
//  * @returns The result
//  */
export const categByType = async (type) => {
    const isLoggedIn = store.getState().UserReducer.isLoggedIn;

    if (isLoggedIn) {
      let token = localStorage.getItem("token");
      let res = await fetch(`${api.apiURL}/${prefix}/categByType`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(type),
      });
    
      return await res.json();
    } else {
      let res = await fetch(`${api.apiURL}/${prefix}/defaultShopCategoriesByType`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(type),
      });
    
      return await res.json();
    }
};

/**
 * Get the categories accoridng to the type selected
 * @param {string} type The type to get the categories from
 * @returns The result
 */
 export const productsByCateg = async (category) => {
  const isLoggedIn = store.getState().UserReducer.isLoggedIn;

  if (isLoggedIn) {
    let token = localStorage.getItem("token");
    let res = await fetch(`${api.apiURL}/${prefix}/productsByCateg`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(category),
    });
  
    return await res.json();
  } else {
    let res = await fetch(`${api.apiURL}/${prefix}/defaultShopProductsByCategory`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(category),
    });

    const json = await res.json();
  
    return await json;
  }
};
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, doc, getDoc, getDocs, query, where, collection } from "firebase/firestore";
// import { Alert } from "react-native";
// import { createUser } from ".";
// import AsyncStorage from "@react-native-async-storage/async-storage";
// import { t } from "../src/services/i18n";
import { getApp } from "firebase/app";
import store from "../store";
import { showAlert } from "../actions";
import { createUser } from ".";

export async function registration(email, password, name, countryCode, phone, t) {
  try {
    const firebaseApp = getApp();

    const auth = getAuth(firebaseApp);
    await createUserWithEmailAndPassword(auth, email, password);
    const currentUser = auth.currentUser;
    let values = {
      Name: name,
      uid: currentUser.uid,
      Phone: phone,
      CountryCode: countryCode,
      Email: currentUser.email,
      Creation_date: Date.now(),
    };
    let res = await createUser(values);

    return res;
  } catch (err) {
    console.log('Error: ', err);

    let message = '';

    if (err.message.includes('auth/email-already-in-use')) {
      message = 'email_already_in_use';
    }
    if (err.message.includes('auth/network-request-failed')) {
      message = 'network_request_failed';
    }
    if (err.message.includes('auth/too-many-requests')) {
      message = 'too_many_requests';
    }
    if (err.message.includes('auth/web-storage-unsupported')) {
      message = 'web_storage_unsupported';
    }
    return {
      error: true,
      // message: t("common:There_is_something_wrong") + err.message,
      message: t('common:' + message),
    };
  }
}

export async function signIn(email, password) {
  try {
    const firebaseApp = getApp();

    const auth = getAuth(firebaseApp);
    let response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (err) {
    store.dispatch(showAlert('warning', 'There is something wrong'));
  }
}

export async function loggingOut() {
  const firebaseApp = getApp();

  const auth = getAuth(firebaseApp);
  try {
    await auth.signOut();
    // await localStorage.setItem("token", "");
  } catch (err) {
    store.dispatch(showAlert('warning', 'There is something wrong'));
  }
}

/**
 * Gets the order matching the id provided
 * @param {string} id
 */
export async function getOneOrder(id) {
  const firebaseApp = getApp();
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "orders");
  const docRef = doc(collectionRef, id);

  const orderSnap = await getDoc(docRef);
  if (orderSnap.exists) {
    const order = {
      ...orderSnap.data(),
      id: orderSnap.id,
    };
    return order;
  }
  return null;
}

/**
 * Check if the user has an address attached
 * @param {string} uid: The unique user ID provided by Firebase
 */
export async function userHasaddress(uid) {
  // declare firestore
  const firebaseApp = getApp();
  const db = getFirestore(firebaseApp);
  if (!uid) {
    const auth = getAuth(firebaseApp);
    uid = auth.currentUser.uid;
  }

  // Get the user from firebase
  const colRef = collection(db, 'addresses');
  const q = query(colRef, where('Uid', "==", uid));
  const snapshot = await getDocs(q);

  // check if any addresses came back
  if (snapshot.size > 0) {
    return true;
  }
  return false;
}

import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { setUserData, showAlert } from '../../actions';
import { AddAddress, AddNewAddress, getUser, updateAddress } from '../../API';
import { t } from '../../services/i18n';

type editAddressData = {
  new_address: {
    id: any;
    Geolocation: {
        lat: any;
        long: any;
    };
    FirstLine: string;
    SecondLine?: string;
    label?: string;
    Country: string;
    City: string;
    PostalCode: string;
    Uid: any;
    Is_default: boolean;
  }
}

function AddressForm({ pos }: any) {
  const { userData } = useSelector((state: RootStateOrAny) => state.UserReducer);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { state: paramAddress }: any = useLocation();
  const navigate = useNavigate();

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      address: paramAddress?.FirstLine || '',
      addressSupplement: paramAddress?.SecondLine || '',
      zipCode: paramAddress?.PostalCode || '',
      city: paramAddress?.City || '',
      country: paramAddress?.Country || '',
      label: paramAddress?.label || '',
      isDefault: paramAddress?.Is_default || false,
    },
    validationSchema: yup.object({
      address: yup
        .string()
        .required(t('common:address_field_is_required')),
      zipCode: yup
        .string()
        .required(t('common:zip_code_is_required')),
      city: yup
        .string()
        .required(t('common:city_field_is_required')),
      country: yup
        .string()
        .required(t('common:country_field_is_required')),
    }),
    async onSubmit(values) {
      let res;
      if (pathname === '/address-selection') {
        const data = {
          Geolocation: {
            lat: pos.latitude,
            long: pos.longitude,
          },
          FirstLine: values.address,
          SecondLine: values.addressSupplement,
          label: values.label,
          Country: values.country,
          City: values.city,
          PostalCode: values.zipCode,
          Uid: userData && userData?.uid,
          Is_default: true,
        }
        res = await AddAddress(data);
      } else if (pathname === '/add-address') {
        const data = {
          new_address: {
            Geolocation: {
              lat: pos.latitude,
              long: pos.longitude,
            },
            FirstLine: values.address,
            SecondLine: values.addressSupplement,
            label: values.label,
            Country: values.country,
            City: values.city,
            PostalCode: values.zipCode,
            Uid: userData && userData?.uid,
            Is_default: values.isDefault,
          },
          current_address: null,
        }
        res = await AddNewAddress(data);
      } else if (pathname === '/edit-address') {
        const data: editAddressData = {
          new_address: {
            id: paramAddress.id,
            Geolocation: {
              lat: pos.latitude,
              long: pos.longitude,
            },
            FirstLine: values.address,
            SecondLine: values.addressSupplement,
            label: values.label,
            Country: values.country,
            City: values.city,
            PostalCode: values.zipCode,
            Uid: userData && userData?.uid,
            Is_default: values.isDefault,
          },
        };
        if (!data.new_address.label) {
          delete data.new_address.label;
        }
        if (!data.new_address.SecondLine) {
          delete data.new_address.SecondLine;
        }
        res = await updateAddress(data);

        if (res.error === false) {
          // lets update the global user addresses
          const user = JSON.parse(JSON.stringify(userData));
          // update old default address
          if (data.new_address.Is_default) {
            const foundIndex = user.addresses.findIndex((x: any) => x.Is_default);
            if (foundIndex !== -1) {
              user.addresses[foundIndex].Is_default = false;
            }
          }
          const addressIndex = user.addresses.findIndex((address: any) => address.id === paramAddress.id);
          user.addresses[addressIndex] = data.new_address;
          // dispatch user with new updated address
          dispatch(setUserData(user, true));

          navigate('/my-addresses');
          dispatch(showAlert('success', t("common:The_Address_was_updated_successfully")));
        } else {
          dispatch(showAlert('error', res.message || res.error));
        }
        return;
      }

      if (res.error === false) {
        // fetch the user data and update shop id and address
        const userInfo = await getUser();
        if (userInfo.error === false) {
          userInfo.data.user.uid = userInfo?.data?.user?.id;
          userInfo.data.user.hasAddress = userInfo?.hasAddress;
          userInfo.data.user.addresses = userInfo?.data?.address;
          userInfo.data.user.shop = userInfo?.data?.shop;
          userInfo.data.user.subscription = userInfo?.data?.subscription;
          dispatch(setUserData(userInfo?.data?.user, true));
        }

        if (pathname === '/address-selection') {
          navigate('/');
          dispatch(showAlert('success', t("common:The_Address_Added_successfully")));
        } else if (pathname === '/add-address') {
          navigate('/my-addresses');
          dispatch(showAlert('success', t("common:The_Address_Added_successfully")));
        }
      } else {
        dispatch(showAlert('error', res.message || res.error));
      }
    }
  })

  return (
    <Box className="control-panel" sx={{ maxHeight: 'calc(100vh - 90px)' }}>
      <p>{t("common:Address_Add_Description")}</p>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth margin="normal" color='primary'
          name="address" label={t("common:address_line_1")}
          defaultValue={values.address}
          autoFocus
          onChange={handleChange}
          error={touched.address && Boolean(errors.address)}
        />
        <TextField
          fullWidth margin="normal" color='primary'
          name="addressSupplement" label={t("common:address_line_2")}
          defaultValue={values.addressSupplement}
          onChange={handleChange}
        />
        <Stack direction='row' gap={2}>
          <TextField
            fullWidth margin="normal" color='primary'
            name="city" label={t("common:city")}
            defaultValue={values.city}
            onChange={handleChange}
            error={touched.city && Boolean(errors.city)}
          />
          <TextField
            fullWidth margin="normal" color='primary'
            name="country" label={t("common:country")}
            defaultValue={values.country}
            onChange={handleChange}
            error={touched.country && Boolean(errors.country)}
          />
        </Stack>
        <Stack direction='row' gap={2}>
          <TextField
            fullWidth margin="normal" color='primary'
            name="zipCode" label={t("common:zipcode")}
            defaultValue={values.zipCode}
            onChange={handleChange}
            error={touched.zipCode && Boolean(errors.zipCode)}
          />
          <TextField
            fullWidth margin="normal" color='primary'
            name="label" label={t("common:address_label")}
            defaultValue={values.label}
            onChange={handleChange}
          />
        </Stack>

        <FormControlLabel
          control={<Checkbox checked={values.isDefault} color="primary" />}
          name='isDefault'
          defaultValue={values.isDefault}
          label={t('common:make_it_as_default_address')}
          onChange={handleChange} 
        />

        <LoadingButton
          loading={isSubmitting}
          type="submit"
          fullWidth
          color='primary'
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
          disableElevation
        >
          {t("common:CONTINUE")}
        </LoadingButton>
      </form>
    </Box>
  );
}

export default React.memo(AddressForm);
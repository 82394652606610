import { Button, Container, Stack } from "@mui/material";
import { useState } from "react";
import RequestModal from "../../components/RequestModal";
import { t } from "../../services/i18n";
import CleanzyDelivery from "./CleanzyDelivery";
import SelfService from "./SelfService";

export default function DeliveryMethod() {
  const types = [
    { id: 'cleanzyDelivery', name: t("common:Cleanzy_Delivery") },
    { id: 'selfService', name: t("common:Self_Service") },
  ]

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("cleanzyDelivery");
  const [openRequestModal, setOpenRequestModal] = useState(false);

  return (
    <Container maxWidth='md' sx={{ height: '100vh' }}>
      <Stack spacing={2} direction="row" justifyContent='center' mt={3}>
        {
          types.map(({ id, name }) => (
            <Button 
              disableElevation
              variant={id === selectedDeliveryMethod ? 'contained' : 'outlined'} 
              color={id === selectedDeliveryMethod ? 'primary' : 'secondary'}
              onClick={() => setSelectedDeliveryMethod(id)} 
              key={id}
            >
              {name}
            </Button>
          ))
        }
      </Stack>

      {
        selectedDeliveryMethod === 'cleanzyDelivery' ? (
          <CleanzyDelivery />
        ) : (
          <SelfService />
        )
      }

      <RequestModal 
        open={openRequestModal}
        handleClose={() => setOpenRequestModal(false)}
      />
    </Container>
  )
}
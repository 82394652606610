import { Grow, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../actions";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GrowTransition(props) {
  return <Grow {...props} />;
}

export default function CustomSnackBar() {
  const { type, message, opened } = useSelector(state => state.alert);

  const dispatch = useDispatch();

  const handleClose = (e, reason) => {
    if (reason === 'clickaway')
      return;
      dispatch(hideAlert('success', 'Successful sign in !'));
  };

  return (
    <Snackbar
      open={opened}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={GrowTransition}
      autoHideDuration={1500}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}

import { FacebookOutlined, Google, Apple } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import UseSignInWithFacebook from '../helpers/UseSignInWithFacebook';
import UseSignInWithGoogle from '../helpers/UseSignInWithGoogle';
import { t } from '../services/i18n';


function signInWithApple() {
  
}

export default function SignUpMethods() {
  const signUpMethods = [
    { name: t("common:Continue_with_Google"), icon: Google, signIn: UseSignInWithGoogle() },
    { name: t("common:Continue_with_Facebook"), icon: FacebookOutlined, signIn: UseSignInWithFacebook },
    { name: t("common:Continue_with_Apple"), icon: Apple, signIn: signInWithApple },
  ]
  
  return <>
    <Divider sx={{ marginTop: 4 }}>
      <Typography color='secondary'>{t("common:Or")}</Typography>
    </Divider>

    <Box marginTop={3}>
      {
        signUpMethods.map(method => (
          <Button
            variant='outlined'
            color='secondary'
            disableElevation
            fullWidth
            sx={{ padding: 1, backgroundColor: '#f9f9f9', mb: 1 }}
            key={method.name}
            onClick={method.signIn}
          >
            <method.icon sx={{ mr: 1 }} />
            <Typography color='secondary'>{method.name}</Typography>
          </Button>
        ))
      }
    </Box>
  </>
}
import { useEffect, useRef, useState } from 'react';
import Map, {
  FullscreenControl, GeolocateControl, Marker, NavigationControl, ScaleControl
} from 'react-map-gl';
import { useSelector } from 'react-redux';
import AddressForm from './AddressForm';
import Pin from './Pin';


import mapboxgl from 'mapbox-gl';
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TOKEN = "pk.eyJ1IjoibW1wZ3NvZnR3YXJlcyIsImEiOiJja2kxYnV2bnAwN3JkMnBxbmwwZGQxYm5hIn0.3A9baNUVkmkoqI0vCUp9pw";

export default function MapComponent() {
  const { userData } = useSelector((state: any) => state.UserReducer);

  const INITIAL_POS = { 
    latitude: (userData?.addresses && userData?.addresses[0]?.Geolocation.lat) || 33.588, 
    longitude: (userData?.addresses && userData?.addresses[0]?.Geolocation.long) || -7.6182 
  }
  const [pos, setPos] = useState<any>(INITIAL_POS);

  const mapRef = useRef<any>(null);

  function handleUpdateMarker(e: mapboxgl.MapLayerMouseEvent) {
    setPos({ latitude: e.lngLat.lat, longitude: e.lngLat.lng })
  }
    
  useEffect(() => {
    // let geoTracker = new mapboxgl.GeolocateControl(...);
    // mapRef.current.addControl(geoTracker);
    // geoTracker._geolocateButton.click();
    // console.log(mapRef.current);
    // document.getElementById("mapboxgl-ctrl-geolocate")?.click();
  }, [])
  
  return (
    <div style={{ width: '100%', height: 'calc(100vh - 64px)' }}>
      <Map
        initialViewState={{
          ...INITIAL_POS,
          zoom: 12.9,
          bearing: 0,
          pitch: 0
        }}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={TOKEN}
        onClick={handleUpdateMarker}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />

        <Marker
          {...pos}
          anchor="bottom"
        >
          <Pin />
        </Marker>
      </Map>

      <AddressForm pos={pos} />
    </div>
  );
}
import actionTypes from "../actions/types";

const initialState = {
    opened: false,
    type: '',
    message: '',
};

const AlertReducer = (state, { type, payload }) => {
    state = state || initialState;
    switch (type) {
        case actionTypes.SHOW_ALERT:
            return { ...payload, opened: true };
        case actionTypes.HIDE_ALERT:
            return { ...state, opened: false };
        default:
            return state;
    }
};

export default AlertReducer;
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getSubscriptions } from "../../API";
import { t } from "../../services/i18n";
import Subscription from "./Subscription";

export default function Subscriptions() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const types = [
    { route: "/pressing", name: t("common:Order") },
    { route: "/subscriptions", name: t("common:Subscriptions") },
    { route: "/shop", name: t("common:Shop") },
  ];

  const { isLoading, data: subscriptions } = useQuery(
    "subscriptions",
    async () => {
      const sub = await getSubscriptions();
      if (sub.error === false) {
        return sub.data;
      }
    }
  );

  return (
    <Container maxWidth="md" sx={{ height: "100vh" }}>
      <Stack spacing={2} direction="row" justifyContent="center" mt={3}>
        {types.map((type) => (
          <Button
            disableElevation
            variant={type.route === pathname ? "contained" : "outlined"}
            color={type.route === pathname ? "primary" : "secondary"}
            onClick={() => navigate(type.route)}
            key={type.name}
          >
            {type.name}
          </Button>
        ))}
      </Stack>

      {isLoading ? (
        <Box sx={{ mt: 3, textAlign: "center" }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        // <Stack direction='row' justifyContent='center' sx={{ mt: 5 }}>
        //   <SubscriptionsSlider subscriptions={subscriptions} />
        // </Stack>

        <Grid
          container
          marginTop={4}
          rowSpacing={3}
          columnSpacing={3}
          style={{ gridTemplateColumns: "repeat(5, 1fr)" }}
        >
          {((subscriptions || []) as any[]).map(
            (subscription: any, index: number) => (
              <Grid item xs={6} key={`${subscriptions.Id}-${index}`}>
                <Subscription subscription={subscription} />
              </Grid>
            )
          )}
        </Grid>
      )}
    </Container>
  );
}

import { Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import i18n, { t } from "../services/i18n";
import Subscription from "./Home/Subscription";

export default function MySubscription() {
  const { userData } = useSelector((state: any) => state.UserReducer);

  const subscription = userData.subscription;

  return (
    <Container maxWidth='xs' sx={{ height: '100vh', pt: 5 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>{t('common:My_Subscription')}</Typography>
      {
        subscription[`Title_${i18n.locale}`] ? (
          <Subscription subscription={userData.subscription} screen='mySubscription' />
        ) : (
          <Typography 
            variant="body2" color='secondary' 
            textAlign='center'
            sx={{ pt: 3, display: 'block' }}
          >
            {t("common:no_sub_available")}
          </Typography> 
        )
      }
    </Container>
  )
}

// let res = await updateAddress(values);
//       if (res.error === false) {
//         showMessage({
//           message: t("common:Success"),
//           description: t("common:The_Address_was_updated_successfully"),
//           type: "success",
//         });

//         // lets update the global user addresses
//         let user = JSON.parse(JSON.stringify(userData));
//         // update old default address
//         if (values.new_address.Is_default) {
//           var foundIndex = user.addresses.findIndex(
//             (x) => x.Is_default === true
//           );
//           if (foundIndex !== -1) {
//             user.addresses[foundIndex].Is_default = false;
//           }
//         }
//         user.addresses[addressIndex] = values.new_address;

//         // dispatch user with new updated address
//         dispatch(setUserData(user, true));
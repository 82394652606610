import actionTypes from "./types";
// ACTIONS

/*============================= Layout Actions =================================*/

/**
 *  show or hide app intro
 * @param {Boolean} show
 */
export const setAppIntro = (show) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_LOCALE,
    payload: { show },
  });
};

/*============================= User Actions =================================*/
/**
 * Set User Data
 * @param {Object} user
 * @param {Boolean} isLoggedIn
 */
export const setUserData = (user, isLoggedIn) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_USER,
    payload: { user, isLoggedIn },
  });
};

/*============================= User Actions =================================*/
/**
 * Set User Shop id
 * @param {Object} user
 * @param {string} Shop_id
 */
export const setUserShopId = (Shop_id) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_USER_SHOP_ID,
    payload: { Shop_id },
  });
};

/**
 * Set User Shop
 * @param {Object} shop
 */
 export const setUserShop = (shop) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_USER_SHOP,
    payload: { shop },
  });
};


/**
 * Set User Phone
 */
 export const setUserPhoneAndName = (data) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_USER_PHONE_AND_NAME,
    payload: data,
  });
};

/**
 * Set is registration
 */
 export const setIsRegistration = (data) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_IS_REGISTRATION,
    payload: data,
  });
};

/**
 * clear data and logout user
 *
 */
export const setLogout = () => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_LOGOUT,
    payload: {},
  });
};

/**
 * update cart
 *
 */
export const updateCart = (cartItems, cartCount, totalPrice) => (dispatch) => {
  return dispatch({
    type: actionTypes.UPDATE_CART_ITEMS,
    payload: { cartItems, cartCount, totalPrice },
  });
};

/**
 * set Order
 *
 */
export const setOrder = (order) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_ORDER,
    payload: { order },
  });
};

/**
 * set loading state to show a screen from the
 * RootNavigation.jsx
 */
export const toggleLoader = (value) => (dispatch) => {
  return dispatch({
    type: actionTypes.TOGGLE_LOADING,
    payload: { value },
  });
};

/**
 * The action will trigger a modal to open on top of the app
 * and show the title and body of the notification. One button
 * will serve to open the order details, the other one to close
 * the modal.
 * @param {string } title Notification title received in the prefered user language
 * @param {any} body Notification body received in the prefered user language
 */
export const showOrderModal = (title, body) => (dispatch) => {
  return dispatch({
    type: actionTypes.NOTIFICATION_DISPLAYED,
    payload: { title, body },
  });
};

/**
 * The action will trigger a an alert with the corresponding color and text
 * it will be dissmissed after a duration mentioned in the snackbar component
 * @param {string} type Alert type (success, warning, error...)
 * @param {string} message Message that will be displayed in the alert
 */
export const showAlert = (type, message) => (dispatch) => {
  return dispatch({
    type: actionTypes.SHOW_ALERT,
    payload: { type, message },
  });
};

/**
 * The action will hide the alert
 */
export const hideAlert = () => (dispatch) => {
  return dispatch({
    type: actionTypes.HIDE_ALERT,
    payload: {},
  });
};

/**
 * The action will trigger a modal to open on top of the app
 * and show the title and body of the payment order. One button
 * will serve to open the order details, the other one to close
 * the modal.
 * @param {string } title payment order title received in the prefered user language
 * @param {any} body  payment order body received in the prefered user language
 */
export const showPaymentOrderModal = (title, body, type) => (dispatch) => {
  return dispatch({
    type: actionTypes.PAYMENT_ORDER_DISPLAYED,
    payload: { title, body, type },
  });
};

/**
 * This action will close the notification modal and clean the
 * notification reducer
 */
export const closeOrderModal = () => (dispatch) => {
  return dispatch({
    type: actionTypes.CLOSE_NOTIFICATION_DISPLAY,
    payload: {},
  });
};

/**
 * This action will close the payment modal and clean the
 * order  reducer
 */
export const closePaymentOrderModal = () => (dispatch) => {
  return dispatch({
    type: actionTypes.CLOSE_PAYMENT_ORDER_DISPLAY,
    payload: {},
  });
};

/**
 * This action resets the body and title values of
 * the notification reducer resulting in the notificationModal
 * component to appear empty
 */
export const resetNotificationValues = () => (dispatch) => {
  return dispatch({
    type: actionTypes.RESET_VALUES,
    payload: {},
  });
};

/**
 * This action resets the body and title values of
 * the order reducer resulting in the PaymentOrderModal
 * component to appear empty
 */
export const resetPaymentOrderModalValues = () => (dispatch) => {
  return dispatch({
    type: actionTypes.PAYMENT_ORDER_MODAL_RESET_VALUES,
    payload: {},
  });
};

export const setCategoryProducts = (catId, products) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_CATEGORY,
    payload: { catId: catId.category, products },
  });
};

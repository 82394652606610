import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n, { t } from "../../services/i18n";
import { ucwords } from "../../utils";

export default function SelfService() {
  const { userData: { shop } } = useSelector((state: any) => state.UserReducer);
  const navigate = useNavigate();

  const sorter: any = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  const weekSchedule =
    shop?.Week_schedule &&
    Object.entries(shop?.Week_schedule).sort((a, b) => {
      const day1 = a[0].toLowerCase();
      const day2 = b[0].toLowerCase();
      return sorter[day1] - sorter[day2];
    });

  return (
    <Container maxWidth='sm' sx={{ pt: 5 }}>
      <Typography variant="h4">{t("common:Self_Service_Title")}</Typography>
      <Typography>
        {t("common:You_can_go_deliver_or_pick_up_your_items_at_the_following_location")}
      </Typography>

      <Divider sx={{ my: 3 }}></Divider>

      <Typography variant="h4">{t("common:shop_details")}</Typography>
      <Typography>
        {shop[`Name_${i18n.locale}`]} <br />
        {shop.shopAddress?.Road} {shop.shopAddress?.Number} <br />
        {shop.shopAddress?.PostalCcode} {shop.shopAddress?.City}
      </Typography>

      <Divider sx={{ my: 3 }}></Divider>

      <Typography variant="h4">{t("common:Openning_hours")}</Typography>
      <Grid container rowGap={3} marginTop={2}>
        {
          weekSchedule.map(([name, timeRanges]: any[], i: number) => (
            <Grid item xs={4} key={i}>
              <Typography variant="h6">{t(`common:${name}`)}</Typography>
              {
                timeRanges.length !== 0 ? (
                  timeRanges.map((timeRange: any) => (
                    <React.Fragment key={timeRange}>
                      {timeRange} <br />
                    </React.Fragment>
                    ))
                ) : (
                  <Typography color='error'>{ucwords(t("common:closed"))}</Typography>
                )
              }
            </Grid>
          ))
        }
        {/* <Grid item xs={4}>
          <Typography variant="h6">Tuesay</Typography>
          08:00 - 20:00
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Wednesday</Typography>
          08:00 - 20:00
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Thursday</Typography>
          08:00 - 20:00
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Friday</Typography>
          08:00 - 20:00
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Saturday</Typography>
          08:00 - 20:00
        </Grid> */}
      </Grid>
      
      <Button
        onClick={() => navigate('/payment', { state: { from: 'selfService' } })}
        fullWidth
        color='primary'
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disableElevation
      >
        {t("common:To_Payement")}
      </Button>
    </Container>
  )
}
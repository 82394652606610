import { LocalLaundryService } from '@mui/icons-material';
import { Badge, Fab, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../services/i18n';
import CartDrawer from './CartDrawer';

const cartFABStyle = {
  position: 'fixed',
  right: 50,
  bottom: 30,
  borderRadius:2,
  px: 3
}

export default function Cart({ handleOpenSignInModal }: any) {
  const [show, setShow] = useState(false);
  const { cartCount } = useSelector((state: any) => state.LayoutReducer);
  const user = useSelector((state: any) => state.UserReducer);

  function toggleDrawer(open: any) {
    if (open && cartCount) {
      setShow(true);
    } else if (!open) {
      setShow(false);
    }
  }

  useEffect(() => {
    if (!cartCount) {
      setShow(false);
    }
  }, [cartCount])
  
  function handleOpenMenu() {
    if (user.isLoggedIn) {
      toggleDrawer(true);
    } else {
      // handleOpenSignInModal();
    }
  }

  return (
    <>
      <IconButton 
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 2 }}
        onClick={handleOpenMenu}
      >
        <Badge badgeContent={cartCount} color="error">
          <LocalLaundryService />
        </Badge>
      </IconButton>

      <CartDrawer show={show} toggleDrawer={toggleDrawer} />

      {
        cartCount !== 0 ? (
          <Fab 
            variant='extended' color="primary" 
            onClick={handleOpenMenu} 
            sx={cartFABStyle}
          >
            {t('Go_To_Cart')}
          </Fab>
        ) : null
      }
    </>
  );
}
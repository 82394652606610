import actionTypes from "../actions/types";

const initialState = {};

const ProductsReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case actionTypes.SET_CATEGORY:
      const payload = action.payload;
      const catId = payload.catId;
      const products = payload.products;
      state[catId] = products;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ProductsReducer;

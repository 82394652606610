import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setOrder, setUserData, showAlert, updateCart } from "../actions";
import { checkCoupon, checkFees, createOrder } from "../API";
import * as keys from "../config/keys";
import { t } from "../services/i18n";

type Values = {
  user: any;
  products: any;
  description: any;
  price: any;
  totalGrossPrice: any;
  delivery_fee: any;
  additional_services_fee: any;
  expressDeliveryFee: any;
  total: any;
  shopId: any;
  transactions: any;
  pickup?: any;
  delivery?: any;
  delivery_type?: any;
  paymentMethod?: any;
  couponCode?: any;
};

export default function Payment() {
  const { userData } = useSelector((state: any) => state.UserReducer);
  const { cartItems, totalPrice } = useSelector(
    (state: any) => state.LayoutReducer
  );

  const [couponCode, setCouponCode] = useState("");
  const [couponReduction, setcouponReduction] = useState(0);
  const [description, setDescription] = useState("");
  const [Price, setPrice] = useState(totalPrice);
  const [conditionsChecked, setConditionsChecked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [sumAdditional, setsumAdditional] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [expressDeliveryFee, setExpressDeliveryFee] = useState(0);
  const [orderID, setOrderID] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const paymentSubmitRef = useRef<any>(null);

  const { state }: any = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let addionalTotal = cartItems.reduce((ac: any, value: any) => {
        return ac + (value.sumAdditional * value.quantity || 0);
      }, 0);
      setsumAdditional(addionalTotal);
      if (
        cartItems[0]?.type === "subscription" ||
        state?.fromScreen === "selfService"
      ) {
        setDeliveryFee(0);
      } else {
        // Express delivery costs 40dhs,
        if (state?.values?.delivery_type === "Express_Delivery") {
          setExpressDeliveryFee(40);
        }
        let res = await checkFees(totalPrice);
        if (res.error === false && res.data.hasFees) {
          setDeliveryFee(res.data.fees);
        }
      }
    })();

    return () => {};
  }, []);

  async function handleValidateCoupon() {
    if (!couponCode) {
      dispatch(showAlert("warning", t("common:Please_enter_the_coupon_first")));
      return;
    } else {
      let res = await checkCoupon({ code: couponCode, price: totalPrice });
      if (res.error === false) {
        setPrice(res.data.newPrice);
        setcouponReduction(res.data.couponReduction);
        dispatch(
          showAlert("success", t("common:The_coupon_applied_successfully"))
        );
      } else {
        dispatch(
          showAlert(
            "error",
            res.message || t("common:something_wrong_please_try_again")
          )
        );
      }
    }
  }

  const handleCreateOrder = async () => {
    // reject if the conditions are not marked true
    if (!conditionsChecked) {
      dispatch(showAlert("warning", t("must_accept_our_politics")));
      return;
    }

    let Items = JSON.parse(JSON.stringify(cartItems));
    if (Items.length <= 0) {
      dispatch(
        showAlert("warning", t("common:please_choose_some_product_first"))
      );
      return;
    }
    Items.map((item: any) => {
      item.SpecialServices = item.SpecialServicesUsed;
      return item;
    });
    let values: Values = {
      user: userData.uid,
      products: Items,
      description,
      price: parseInt(Price),
      totalGrossPrice: totalPrice,
      delivery_fee: deliveryFee,
      additional_services_fee: sumAdditional,
      expressDeliveryFee: expressDeliveryFee,
      total: Math.round(
        deliveryFee + parseInt(Price) + sumAdditional + expressDeliveryFee
      ),
      shopId: userData.Shop_id,
      transactions: [],
    };

    if (Items[0]?.type === "subscription") {
      values.pickup = {};
      values.delivery = {};
    } else {
      values.pickup = {
        address: state?.values?.selectedPickupAddress,
        date: state?.values?.selectedPickupDate,
        time: state?.values?.selectedPickupTime,
      };
      values.delivery = {};
      values.delivery_type = state?.values?.delivery_type;
    }

    values.paymentMethod = paymentMethod;
    if (couponCode) {
      values.couponCode = {
        name: couponCode,
        value: couponReduction,
      };
    }

    setIsSubmitting(true);
    let res = await createOrder(values);
    const orderId = res?.data?.id;
    if (res.error === false) {
      dispatch(setOrder(res.data));
      let user = JSON.parse(JSON.stringify(userData));
      if (user.subscription) {
        user.subscription.consumedProducts = res.consumedProducts;
      }
      dispatch(setUserData(user, true));
      let query = "";
      if (paymentMethod === "creditCard") {
        setOrderID(orderId);
        // save token to localStorage
        localStorage.setItem("orderId", orderId);
        paymentSubmitRef.current.click();
        dispatch(updateCart([], 0, 0));
      } else {
        query = "?orderId=" + orderId;
        dispatch(updateCart([], 0, 0));
      }
      navigate("/my-orders" + query);
    } else {
      dispatch(
        showAlert("error", t("common:something_wrong_please_try_again"))
      );
    }
    setIsSubmitting(false);
  };

  let totalWithVAT =
    deliveryFee + parseInt(Price) + sumAdditional + expressDeliveryFee;

  function getParams() {
    let billToName = userData && userData.Name;
    let billToEmail = userData && userData.Email;

    let billToPhone;
    if (userData && userData.Phone !== null) {
      billToPhone = userData.Phone;
    } else {
      billToPhone = null;
    }

    const params: any[] = [
      { name: "isWeb", value: "true" },
      { name: "billToName", value: billToName },
      { name: "lang", value: "fr" },
      { name: "email", value: billToEmail },
      { name: "amount", value: Math.round(totalWithVAT) },
      { name: "oid", value: orderID },
    ];
    if (billToPhone !== null) {
      params.push({ name: "phone", value: billToPhone });
    }
    return params;
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ pt: 5 }}>
        <Typography variant="h5">
          {t("common:Select_payment_method")}
        </Typography>
        <FormControl>
          <RadioGroup
            row
            defaultValue="creditCard"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <FormControlLabel
              value="creditCard"
              control={<Radio />}
              label={t("common:Credit_card")}
            />
            {cartItems[0]?.type !== "subscription" && (
              <FormControlLabel
                value="cash"
                control={<Radio />}
                label={t("common:Cash")}
              />
            )}
          </RadioGroup>
        </FormControl>

        <Typography color="GrayText">
          {paymentMethod === "creditCard"
            ? t("common:payment_card_info")
            : state?.fromScreen === "selfService"
            ? t("common:cashPaymentSelfService")
            : t("common:payment_cash_info")}
        </Typography>

        <Typography variant="h5" marginTop={3}>
          {t("common:Promotional_code")}
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          color="primary"
          name="couponCode"
          label={t("common:Enter_coupon_code")}
          placeholder={t("common:Enter_coupon_code")}
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <Link
          variant="body2"
          color="secondary"
          textAlign="end"
          sx={{ cursor: "pointer", display: "block" }}
          onClick={handleValidateCoupon}
        >
          {t("common:Validate")}
        </Link>

        <Typography variant="h5" marginTop={3}>
          {t("common:description_of_additional_needs")}
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          color="primary"
          multiline
          rows={2}
          name="couponCode"
          placeholder={t("common:enter_your_needs_here")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Typography variant="h5" marginTop={3} marginBottom={1}>
          {t("common:Price_Detail")}
        </Typography>
        <Typography color="GrayText">
          <Stack
            direction="row"
            justifyContent="space-between"
            marginBottom={1}
          >
            <Box>{t("common:Price")}</Box>
            <Box>{totalPrice} dhs</Box>
          </Stack>
          {sumAdditional !== 0 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Box>{t("common:Additional_services")}</Box>
              <Box>{sumAdditional} dhs</Box>
            </Stack>
          )}
          {couponReduction !== 0 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Box>{t("common:Coupon")}</Box>
              <Box>-{couponReduction} %</Box>
            </Stack>
          )}
          {deliveryFee !== 0 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Box>{t("common:Delivery_Fee")}</Box>
              <Box>{deliveryFee} dhs</Box>
            </Stack>
          )}
          {expressDeliveryFee !== 0 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Box>{t("common:Express_Delivery")}</Box>
              <Box>{expressDeliveryFee} dhs</Box>
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Box>{t("common:Total")}</Box>
            <Box>{Math.round(totalWithVAT)} dhs</Box>
          </Stack>
        </Typography>

        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Checkbox
              checked={conditionsChecked}
              onChange={(e) => setConditionsChecked(e.target.checked)}
              color="primary"
            />
          }
          name="isAcceptGCU"
          label={
            <Link
              sx={{ color: "gray" }}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://cleanzy.ma/conditions", "_blank");
              }}
            >
              {t("accept_terms_of_payment")}
            </Link>
          }
        />

        <LoadingButton
          sx={{ my: 2 }}
          onClick={handleCreateOrder}
          fullWidth
          loading={isSubmitting}
          type="submit"
          variant="contained"
          disableElevation
        >
          {t("common:PAY")} {Math.round(totalWithVAT)} dhs
        </LoadingButton>
      </Container>

      <form action={keys.default.paymentUrl} method="post" target="_blank">
        {getParams().map((param: any) => (
          <input
            name={param.name}
            value={param.value || ""}
            type="hidden"
            key={param.name}
          />
        ))}
        <Button
          ref={paymentSubmitRef}
          type="submit"
          style={{ display: "hidden" }}
        />
      </form>
    </>
  );
}

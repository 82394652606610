// // ! LOCAL
// const local = {
//   firebaseConfig: {
//     apiKey: "AIzaSyAXGWhXHg5PJjJQXUfQJKqr6vaQabSqIa8",
//     authDomain: "cleanzy-apps-dev.firebaseapp.com",
//     projectId: "cleanzy-apps-dev",
//     storageBucket: "cleanzy-apps-dev.appspot.com",
//     messagingSenderId: "357798630245",
//     appId: "1:357798630245:web:6c11ac3c0f7b1e0d43878c",
//   },
//   apiURL: "http://localhost:8080/",
//   paymentUrl: "https://payment.dev.cleanzy.ma/payment.php",
//   cmiPaymentUrl: "https://testpayment.cmi.co.ma/fim/est3Dgate",
// };
// export default local;

// ! STAGE
// const stage = {
//   firebaseConfig: {
//     apiKey: "AIzaSyAXGWhXHg5PJjJQXUfQJKqr6vaQabSqIa8",
//     authDomain: "cleanzy-apps-dev.firebaseapp.com",
//     projectId: "cleanzy-apps-dev",
//     storageBucket: "cleanzy-apps-dev.appspot.com",
//     messagingSenderId: "357798630245",
//     appId: "1:357798630245:web:6c11ac3c0f7b1e0d43878c",
//   },
//   apiURL: "https://api.dev.cleanzy.ma/v2",
//   paymentUrl: "https://payment.dev.cleanzy.ma/payment.php",
//   cmiPaymentUrl: "https://testpayment.cmi.co.ma/fim/est3Dgate",
// };
// export default stage;

// // ! PROD
const prod = {
  firebaseConfig: {
    apiKey: "AIzaSyD8CSuzNvfrblz8gSv1PzA6Ws3Vmy19VvE",
    authDomain: "cleanzy-dev.firebaseapp.com",
    projectId: "cleanzy-dev",
    storageBucket: "cleanzy-dev.appspot.com",
    messagingSenderId: "934715924880",
    appId: "1:934715924880:web:ee8d0880ec6d9cac5ed315",
    measurementId: "G-ZCCPSKFDYG",
  },

  apiURL: "https://api.cleanzy.ma/v2",
  paymentUrl: "https://payment.cleanzy.ma/payment.php",
  cmiPaymentUrl: "https://payment.cmi.co.ma/fim/est3Dgate",
};
export default prod;

import { initializeApp } from "firebase/app";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import {
  setIsRegistration,
  setUserData,
  toggleLoader
} from "./actions";
import { getUser } from "./API";
import CustomSnackBar from "./components/CustomSnackBar";
import Layout from "./components/Layout";
import apiKeys from "./config/keys";
import useBreakPoints from "./hooks/useBreakPoints";
import i18n from "./services/i18n";
import store from "./store";
import AddressSelection from "./views/AddressSelection";
import ContactCleanzy from "./views/ContactCleanzy";
import DeliveryMethod from "./views/DeliveryMethod";
import Pressing from "./views/Home/Pressing";
import Shop from "./views/Home/Shop";
import Subscriptions from "./views/Home/Subscriptions";
import Login from "./views/Login";
import MobileApp from "./views/MobileApp";
import MyAddresses from "./views/MyAddresses";
import MyOrders from "./views/MyOrders";
import MySubscription from "./views/MySubscription";
import Payment from "./views/Payment";
import Profile from "./views/Profile";
import Register from "./views/Register";
import ShopSelection from "./views/ShopSelection";

function App() {
  const firebase = initializeApp(apiKeys.firebaseConfig);

  const { windowSize: { width }, breakPoints } = useBreakPoints();

  useEffect(() => {
    (async () => {
      await i18n.init()

      const auth = getAuth(firebase);
      // Listen for authentication state to change.
      onAuthStateChanged(auth, async (user) => {
        if (user && auth.currentUser) {
          const token = await getIdToken(auth.currentUser, true);
          localStorage.setItem("token", token);
          const userInfo = await getUser();
          if (userInfo.error === false) {
            userInfo.data.user.uid = userInfo?.data?.user?.Id;
            userInfo.data.user.hasAddress = userInfo?.hasAddress;
            userInfo.data.user.addresses = userInfo?.data?.address;
            userInfo.data.user.shop = userInfo?.data?.shop;
            userInfo.data.user.subscription = userInfo?.data?.subscription;
            store.dispatch(async (dispatch: any) => {
              await dispatch(setUserData(userInfo?.data?.user, true));
            });
            console.log(userInfo?.data?.user);
            store.dispatch(toggleLoader(false));
            store.dispatch(setIsRegistration(false));
          }
        } else {
          store.dispatch(toggleLoader(false));
        }
      });
    })();
  }, [firebase])  
  
  if (width && width < breakPoints.xs) {
    return <MobileApp />
  }

	return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/address-selection" element={<Layout><AddressSelection /></Layout>}></Route>
        <Route path="/shop-selection" element={<Layout><ShopSelection /></Layout>}></Route>
        <Route path="/" element={<Navigate replace to="/pressing" />}></Route>
        <Route path="/pressing" element={<Layout><Pressing /></Layout>}></Route>
        <Route path="/subscriptions" element={<Layout><Subscriptions /></Layout>}></Route>
        <Route path="/shop" element={<Layout><Shop /></Layout>}></Route>
        <Route path="/delivery-method" element={<Layout><DeliveryMethod /></Layout>}></Route>
        <Route path="/payment" element={<Layout><Payment /></Layout>}></Route>
      
        <Route path="/contact-cleanzy" element={<Layout><ContactCleanzy /></Layout>}></Route>
        <Route path="/my-orders" element={<Layout><MyOrders /></Layout>}></Route>
        <Route path="/my-subscription" element={<Layout><MySubscription /></Layout>}></Route>
        <Route path="/my-addresses" element={<Layout><MyAddresses /></Layout>}></Route>
        <Route path="/add-address" element={<Layout><AddressSelection /></Layout>}></Route>
        <Route path="/edit-address" element={<Layout><AddressSelection /></Layout>}></Route>
        <Route path="/profile" element={<Layout><Profile /></Layout>}></Route>
      </Routes>
      <CustomSnackBar />
    </Router>
	);
}

export default App;

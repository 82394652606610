import i18next from "i18next";

import * as config from "../../utils/i18n";

import date from "./date";
import languageDetector from "./language-detector";
import translationLoader from "./translation-loader";

const i18n = {
  /**
   * @returns {Promise}
   */
  init: () => {
    return new Promise((resolve, reject) => {
      i18next
        .use(languageDetector)
        .use(translationLoader)
        .init(
          {
            compatibilityJSON: "v3",
            fallbackLng: config.fallback,
            ns: config.namespaces,
            defaultNS: config.defaultNamespace,
            interpolation: {
              escapeValue: false,
              format(value, format) {
                if (value instanceof Date) {
                  return date.format(value, format);
                }
              },
            },
          },
          (error) => {
            if (error) {
              return reject(error);
            }

            date
              .init(i18next.language)
              .then(resolve)
              .catch((error) => reject(error));
          }
        );
    });
  },

  /**
   * @param {string} key
   * @param {Object} options
   * @returns {string}
   */
  t: (key, options=undefined) => i18next.t(key, options),

  /**
   * @returns {string}
   */
  get locale() {
    // return i18next.language;
    return localStorage.locale || 'fr';
  },

  get queryLocale() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return params?.lang;
  },

  // /**
  //  * @returns {'ltr' | 'rtl'}
  //  */
  get dir() {
    return localStorage.locale === 'ar' ? 'rtl' : 'ltr';
  },

  get direction() {
    return localStorage.locale === 'ar' ? 'right' : 'left';
  },

  /**
   * @returns {boolean}
   */
  get isRTL() {
    return localStorage.locale === 'ar';
  },

  /**
   * Similar to React Native's Platform.select(),
   * i18n.select() takes a map with two keys, 'rtl'
   * and 'ltr'. It then returns the value referenced
   * by either of the keys, given the current
   * locale's direction.
   *
   * @param {Object<string,mixed>} map
   * @returns {mixed}
   */
  select(map) {
    const key = this.isRTL ? "rtl" : "ltr";

    return map[key];
  },
};

export const t = i18n.t;

export default i18n;

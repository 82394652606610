import { Box, Stack, Typography } from "@mui/material";
import appStore from '../images/app-store.png';
import googlePlay from '../images/google-play.png';
import { t } from "../services/i18n";

export default function MobileApp() {
  return (
    <Stack 
      alignItems='center' justifyContent='center' 
      paddingX={3} gap={3}
      sx={{ backgroundColor: '#53b3a6',  height: '100vh' }}
    >
      <img src="https://cleanzy.ma/static/logo-white.svg" width={100} alt='' />
        <Typography variant="h5" fontWeight={500} color='white'>
          {t('common:myCleanzyApp')}
        </Typography>
        <Typography color='white' textAlign='center'>
          {t('common:downloadApp')}
        </Typography>

        <Box>
          <img 
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://play.google.com/store/apps/details?id=ma.cleanzy.clients', "_blank")}
            src={googlePlay} 
            width={170} 
            alt=''
          /><br />
          <img 
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://apps.apple.com/us/app/cleanzy-pressing/id1566596264', "_blank")}
            src={appStore} 
            width={170} 
            alt=''
          />
        </Box>
    </Stack>
  )
}
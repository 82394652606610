const actionTypes = {
  SET_USER: "SET_USER",
  SET_USER_SHOP_ID: "SET_USER_SHOP_ID",
  SET_USER_SHOP: "SET_USER_SHOP",
  SET_APP_INTRO: "SET_APP_INTRO",
  UPDATE_CART_ITEMS: "UPDATE_CART_ITEMS",
  SET_LOGOUT: "SET_LOGOUT",
  SET_ORDER: "SET_ORDER",
  TOGGLE_LOADING: "TOGGLE_LOADING",
  NOTIFICATION_DISPLAYED: "NOTIFICATION_DISPLAYED",
  CLOSE_NOTIFICATION_DISPLAY: "CLOSE_NOTIFICATION_DISPLAY",
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
  RESET_VALUES: "RESET_VALUES",
  PAYMENT_ORDER_DISPLAYED: "PAYMENT_ORDER_DISPLAYED",
  CLOSE_PAYMENT_ORDER_DISPLAY: "CLOSE_NOTIFICATION_DISPLAY",
  PAYMENT_ORDER_MODAL_RESET_VALUES: "PAYMENT_ORDER_MODAL_RESET_VALUES",

  SET_USER_PHONE_AND_NAME: "SET_USER_PHONE_AND_NAME",
  SET_IS_REGISTRATION: "SET_IS_REGISTRATION",
  SET_CATEGORY: "SET_CATEGORY",
};

export default actionTypes;

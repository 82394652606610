import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { setIsRegistration, showAlert } from "../actions";
import { createUser } from "../API";
import apiKeys from "../config/keys";
import { t } from "../services/i18n";
import store from "../store";

const firebase = initializeApp(apiKeys.firebaseConfig);
const db = getFirestore(firebase);
const auth = getAuth();
auth.languageCode = "fr";

const isUserEqual = (googleUser, firebaseUser) => {
  if (firebaseUser) {
    var providerData = firebaseUser.providerData;
    for (var i = 0; i < providerData.length; i++) {
      if (
        providerData[i].providerId === GoogleAuthProvider.PROVIDER_ID &&
        providerData[i].uid === googleUser.uid
      ) {
        // We don't need to reauth the Firebase connection.
        return true;
      }
    }
  }
  return false;
};

function UseSignInWithGoogle() {
  const navigate = useNavigate();
  return () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const { idToken, accessToken } =
          GoogleAuthProvider.credentialFromResult(result);
        // The signed-in user info.
        const googleUser = result.user;

        if (!googleUser) {
          return store.dispatch(
            showAlert(t("common:Cannot_Sign_In_With_Google"))
          );
        }
        var unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
          unsubscribe();

          // Check if we are already signed-in Firebase with the correct user.
          if (!isUserEqual(googleUser, firebaseUser)) {
            store.dispatch(setIsRegistration(true));
            var credential = GoogleAuthProvider.credential(
              idToken,
              accessToken
            );
            const resultAuth = await signInWithCredential(
              auth,
              credential
            ).catch((err) => {
              return store.dispatch(showAlert(err));
            });
            if (!resultAuth.user) {
              return store.dispatch(showAlert(t("common:generic_error")));
            }
            const userColl = collection(db, "users");
            const userDocRef = doc(userColl, resultAuth.user.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              updateDoc(userDocRef, {
                last_logged_in: Date.now(),
                Id: resultAuth.user.uid,
              });
              navigate("/");
            } else {
              let values = {
                Name: resultAuth.user.displayName,
                uid: resultAuth.user.uid,
                Email: resultAuth.user.email,
                CountryCode: "+212",
                Creation_date: Date.now(),
                Phone: null,
                Id: resultAuth.user.uid,
              };
              let res = await createUser(values);
              if (res.error === false) {
                navigate("/address-selection");
              } else {
                return store.dispatch(showAlert(res.message || res.error));
              }
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default UseSignInWithGoogle;

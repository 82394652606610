import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';

export default function SignInModal({ open, handleClose }: any) {
  const navigate = useNavigate();

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>You are unauthenticated</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          You need to sign in to continue.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button 
          onClick={() => navigate('/login')} 
          variant='contained' disableElevation
        >
          Sign in
        </Button>
      </DialogActions>
		</Dialog>
	);
}

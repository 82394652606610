import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function useGuard() {
  const navigate = useNavigate();
  const { loading } = useSelector((state: any) => state.LayoutReducer);
  const { isRegistration } = useSelector((state: any) => state.UserReducer);
  const user = useSelector((state: any) => state.UserReducer);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!user.isLoggedIn && !isRegistration) {
      return navigate("/login");
    }

    if (!user.userData.hasAddress) {
      navigate("/address-selection");
    } else if (!user.userData.Shop_id) {
      navigate("/shop-selection");
    }
  }, [user, loading, navigate, isRegistration])
}
const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (callback) => {
    // We will get back a string like "en-US". We
    // return a string like "en" to match our language
    // files.
    let locale = localStorage.getItem("locale");
    callback(locale || 'fr');
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export default languageDetector;

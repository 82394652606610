import plannification from '../images/orderStatus/status_plannification.png'
import collection_ongoing from '../images/orderStatus/status_collection_ongoing.png'
import collected from '../images/orderStatus/status_collected.png'
import ongoing from '../images/orderStatus/status_ongoing.png'
import completed from '../images/orderStatus/status_completed.png'
import cancel from '../images/orderStatus/status_cancel.png'

export default function statusToImage(order: any) {
  return (
    order.type === 'subscription'
      ? completed
    : ["created", "waiting_planification"].includes(order.status)
      ? plannification
    : ["planned", "delivery_planned", 'pickup_ongoing', 'delivery_ongoing'].includes(order.status)
      ? collection_ongoing
    : order.status === "pickedup"
      ? collected
    : ["shop_delivered", "ongoing", 'ready'].includes(order.status)
      ? ongoing
    : order.status === "delivered"
      ? completed
    : cancel
  )
}
import { Button, Container, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "../services/i18n";

export default function ContactCleanzy() {
  const { userData: { shop } } = useSelector((state: any) => state.UserReducer);
  const navigate = useNavigate();

  return (
    <Container maxWidth='xs' sx={{ height: '100vh', pt: 5 }}>
      <Typography variant="h4">{t("common:shop_details")}</Typography>
      <Typography color='grey'>
        {shop.Name_fr} <br />
        {shop.shopAddress?.Road} {shop.shopAddress?.Number} <br />
        {shop.shopAddress?.PostalCcode} {shop.shopAddress?.City}
      </Typography>

      <Divider sx={{ my: 3 }}></Divider>

      <Typography variant="h4">{t("common:shop_contact")}</Typography>
      <Typography>
        {shop.Email} <br />
        {shop.PhoneNumber}
      </Typography>

      <Divider sx={{ my: 3 }}></Divider>
      
      <Button
        onClick={() => navigate('/shop-selection')}
        fullWidth
        color='primary'
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        disableElevation
      >
        {t("common:change_shop")}
      </Button>
    </Container>
  )
}
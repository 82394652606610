import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { showAlert } from "../actions";
import { SaveContactForm } from "../API";
import i18n, { t } from "../services/i18n";

export default function RequestModal({ open, handleClose }: any) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.UserReducer);

  const { values, errors, touched, handleSubmit, handleChange, resetForm, isSubmitting } = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: yup.object({
      subject: yup
        .string()
        .required(t("common:all_fields_are_required_sing")),
      message: yup
        .string()
        .required(t("common:all_fields_are_required_sing")),
    }),
    async onSubmit({ subject, message }) {
      let values = {
        name: userData.Name,
        surname: "no-surname",
        email: userData.Email,
        subject,
        phone: userData.Phone,
        message,
        Shop_id: userData?.shop?.Id,
      };
      let res = await SaveContactForm(values);
      if (res.error === false) {
        dispatch(showAlert('success', t("common:request_sent_successfully")));
        resetForm();
        handleClose();
      } else {
        dispatch(showAlert('error', res.message || res.error));
      }
    }
  });

	return (
		<Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ textAlign: 'left' }}>{t("common:new_product_request_title")}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText sx={{ textAlign: 'left' }}>
            {t("common:new_product_request")}
          </DialogContentText>
          <TextField
            dir={i18n.dir}
            fullWidth margin="normal" color='primary' 
            name="subject" label={t("common:new_request_title")}
            autoFocus
            value={values.subject} onChange={handleChange}
            error={touched.subject && Boolean(errors.subject)}
            helperText={touched.subject && errors.subject}
          />

          <TextField
            dir={i18n.dir}
            fullWidth margin="normal" color='primary' 
            name="message" label={t("common:new_request_body")}
            multiline
            rows={3}
            value={values.message} onChange={handleChange}
            error={touched.message && Boolean(errors.message)}
            helperText={touched.message && errors.message}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common:Cancel")}</Button>
          <LoadingButton 
            loading={isSubmitting}
            type="submit" 
            variant='contained' 
            disableElevation
          >
            {t("common:Save")}
          </LoadingButton>
        </DialogActions>
      </form>
		</Dialog>
	);
}

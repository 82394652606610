import {
	Button,
	Dialog,
	DialogActions,
	DialogContent, DialogTitle, Stack, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../actions";
import statusToImage from "../../helpers/statusToImage";
import { t } from "../../services/i18n";
import { isEmpty, ucwords } from "../../utils";
import ProductsTable from "./ProductsTable";
import ShopRatingModal from "./ShopRatingModal";

export default function DeleteAddressModal({ open, handleClose, order }: any) {
  const { userData: { shop } } = useSelector((state: any) => state.UserReducer);
	const [ratingModal, setOpenRatingModal] = useState(false);
	const [ratingType, setRatingType] = useState('shop');
	const [isRated, setIsRated] = useState({ shop: order?.isShopRated, delivery: order?.isDeliveryRated });

  const dispatch = useDispatch();	

	function handleCopyToClipboard() {
		navigator.clipboard.writeText(order.id?.toUpperCase())
		dispatch(showAlert('success', t("common:Order_id_copied_successfully")));
	}

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>{t(`common:Order_Details`)}</DialogTitle>
      <DialogContent dividers>
				<Stack direction='row' justifyContent='center' marginBottom={3}>
					<img src={statusToImage(order)} width='50%' alt='' />
				</Stack>

				<Stack alignItems='center' marginY={3}>
					<Stack direction='row' alignItems='center' gap={1}>
						<Typography fontWeight='500'>Status:</Typography>
						<Typography fontWeight='500' variant="h6" color='#3a7d74'>
							{
								order.type === "subscription" ?
									ucwords(t(`common:subscription_purchased`))
								:
									ucwords(t(`common:${order.status}`))
							}
						</Typography>
					</Stack>

					<Stack direction='row' gap={1}>
						<Typography fontWeight='500'>ID : </Typography>
						<Typography 
							fontWeight='500' color='#3a7d74' 
							sx={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={handleCopyToClipboard}
						>
							#{order.id?.toUpperCase()}
						</Typography>
					</Stack>
				</Stack>

				<Typography variant="h6" marginTop={2} sx={{ textAlign: 'left' }}>
					{t("common:boutique_traitante")}
				</Typography>
				<Typography color='GrayText'>
					{shop.Name_fr} <br />
					{shop.shopAddress?.Road} {shop.shopAddress?.Number} <br />
					{shop.shopAddress?.PostalCcode} {shop.shopAddress?.City}
				</Typography>
					
				<ProductsTable order={order}/>

				<Box sx={{ mt: 2 }} />

				{
					order?.status === "delivered" && <>
						{
							!isRated.shop && <>
								<Button 
									sx={{ mt: 1 }}
									onClick={() => {
										setRatingType('shop');
										setOpenRatingModal(true);
									}} 
									disableElevation
									fullWidth
									variant='contained'
								>
									{t("common:rate_the_shop")}
								</Button>
								<ShopRatingModal 
									open={ratingModal} 
									handleClose={() => setOpenRatingModal(false)} 
									type={ratingType}
									order={order}
                  isRated={isRated}
                  setIsRated={setIsRated}
								/>
							</>
						}

						{
							!isRated.delivery && !isEmpty(order?.deliveryDetails[0]) && <>
								<Button 
									sx={{ mt: 1 }}
									onClick={() => {
										setRatingType('delivery');
										setOpenRatingModal(true);
									}} 
									disableElevation
									fullWidth
									variant='contained'
								>
									{t("common:rate_the_delivery")}
								</Button>
								<ShopRatingModal 
									open={ratingModal} 
									handleClose={() => setOpenRatingModal(false)} 
									type={ratingType}
									order={order}
                  isRated={isRated}
                  setIsRated={setIsRated}
								/>
							</>
						}
					</>
				}

				{
          order?.status === "payment_failed" && (
						<Button 
							sx={{ mt: 1 }}
							onClick={() => {}} 
							disableElevation
							fullWidth
							variant='contained'
						>
							{t("common:payingAgain")}
						</Button>
					)
				}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disableElevation>Close</Button>
      </DialogActions>
		</Dialog>
	);
}

import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { Avatar, Card, CardContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../actions";
import i18n, { t } from "../../services/i18n";
import { isEmpty } from "../../utils";
import AdditionalServicesModal from "../AdditionalServicesModal";

export default function CartItem({ item, index }: any) {
  const [quantity, setQuantity] = useState(item.quantity);
  const [specialServicesCount, setSpecialServicesCount] = useState(item.SpecialServicesUsed?.length || 0);
  const [additionalServices] = useState(item?.SpecialServices);
  const [openAdditionalServices, setOpenAdditionalServices] = useState(false);

  const dispatch = useDispatch();

  const { userData } = useSelector((state: any) => state.UserReducer);
  const { cartCount, cartItems, totalPrice } = useSelector((state: any) => state.LayoutReducer);

  const updateQuantity = (count: any) => {
    let cart = JSON.parse(JSON.stringify(cartItems));

    let foundIndexInConsumedProducts = [];
    if (userData.subscription && userData.subscription.consumedProducts) {
      foundIndexInConsumedProducts =
        userData.subscription?.consumedProducts.findIndex(
          (vendor: any) => vendor.ProductId === item.Id
        );
    }

    let foundIndexInSubscriptionProducts = [];
    if (userData.subscription && userData.subscription.Products) {
      foundIndexInSubscriptionProducts =
        userData.subscription?.Products.findIndex(
          (vendor: any) => vendor.ProductId === item.Id
        );
    }

    if (userData.subscription && !isEmpty(userData.subscription) && userData.subscription.isActive) {
      // the products is not in consumed products we need to check if it exist in subscription products
      // check if the products is in subscription products
      if (foundIndexInSubscriptionProducts !== -1) {
        if ((quantity === 1 && count < 1) || (quantity === 0 && count < 0)) {
          // here check if the product is in the cart
          if (index !== -1) {
            let SubscriptionProduct =
              userData.subscription?.Products[
                foundIndexInSubscriptionProducts
              ];
            let usedProduct =
              userData.subscription?.consumedProducts[
                foundIndexInConsumedProducts
              ];
            let quant =
              0 -
              (SubscriptionProduct.Quantity -
                (foundIndexInConsumedProducts !== -1
                  ? usedProduct.usedQuantity
                  : 0));
            let newPrice = totalPrice - (quant <= 0 ? 0 : cart[index].Price);

            cart.splice(index, 1);
            let Totalcount = cartCount === 1 ? 0 : cartCount - 1;
            dispatch(updateCart(cart, Totalcount, newPrice));
            setQuantity(0);
          }
          return;
        }
        const newTotalQuantity = quantity + parseInt(count);
        // check if product id already in the cart
        if (index !== -1) {
          let SubscriptionProduct =
            userData.subscription?.Products[foundIndexInSubscriptionProducts];
          let usedProduct =
            userData.subscription?.consumedProducts[
              foundIndexInConsumedProducts
            ];
          let quant =
            newTotalQuantity -
            (SubscriptionProduct.Quantity -
              (foundIndexInConsumedProducts !== -1
                ? usedProduct.usedQuantity
                : 0));
          let newPrice = totalPrice;

          if (newPrice === 0) {
            newPrice =
              quant <= 0 ? 0 : newPrice + quant * parseInt(cart[index].Price);
          } else {
            // here means the count is ( -1 )  so it's -
            if (count === -1) {
              newPrice =
                quant < 0 ? newPrice : newPrice - parseInt(cart[index].Price);
            } else {
              // here the count is ( 1 ) so it's +

              newPrice =
                quant <= 0
                  ? newPrice
                  : newPrice + parseInt(cart[index].Price);
            }
          }

          cart[index].quantity = newTotalQuantity;
          cart[index].fromSubscription = true;
          const itemSum = getCartItemSum(cart);
          dispatch(updateCart(cart, itemSum, parseInt(newPrice)));
          setQuantity(newTotalQuantity);
        } else {
          // here the product is not in the cart but it is in the subscription
          // Add item to the cart

          let cart = JSON.parse(JSON.stringify(cartItems));
          let SubscriptionProduct =
            userData.subscription?.Products[foundIndexInSubscriptionProducts];
          let usedProduct =
            userData.subscription?.consumedProducts[
              foundIndexInConsumedProducts
            ];
          let quant =
            newTotalQuantity -
            (SubscriptionProduct.Quantity -
              (foundIndexInConsumedProducts !== -1
                ? usedProduct.usedQuantity
                : 0));
          let newPrice = totalPrice;

          if (newPrice === 0) {
            newPrice =
              quant <= 0 ? 0 : newPrice + quant * parseInt(item.Price);
          } else {
            // here means the count is ( -1 )  so it's -
            if (count === -1) {
              newPrice =
                quant < 0 ? newPrice : newPrice - parseInt(item.Price);
            } else {
              // here the count is ( 1 ) so it's +

              newPrice =
                quant <= 0 ? newPrice : newPrice + parseInt(item.Price);
            }
          }
          item.quantity = newTotalQuantity;
          item.fromSubscription = true;
          setQuantity(newTotalQuantity);

          const itemSum = getCartItemSum(cart);
          dispatch(updateCart(cart, itemSum, parseInt(newPrice)));
        }

        return;
      }
    }
    checkQuantityNormal(count);
  };

  const getCartItemSum = (items: any) => {
    let sum = 0;
    items.forEach((item: any) => {
      sum += item.quantity;
    });
    return sum;
  };

  const checkQuantityNormal = (count: any) => {
    const newTotalQuantity = quantity + count;
    let cart = JSON.parse(JSON.stringify(cartItems));
    if ((quantity === 1 && count < 1) || (quantity === 0 && count < 0)) {
      let newPrice = totalPrice - parseInt(item.Price) * item.quantity;
      cart[index].quantity = 0;
      cart.splice(index, 1);
      let Totalcount = cartCount - 1;
      dispatch(updateCart(cart, Totalcount, newPrice));
      setQuantity(0);
      return;
    }

    if (cart[index]) {
      let newPrice = totalPrice - parseInt(item.Price) * item.quantity;
      newPrice = newPrice + newTotalQuantity * parseInt(item.Price);
      cart[index].quantity = newTotalQuantity;
      const newCartCount = cartCount + count;
      dispatch(updateCart(cart, newCartCount, newPrice));
      setQuantity(newTotalQuantity);
    }
  };
  
  const onAddionalServicesSaved = (specialServicesIds: any) => {
    let cart = JSON.parse(JSON.stringify(cartItems));
    let data = uniqBy(specialServicesIds, JSON.stringify);
    console.log(data);
    cart[index].SpecialServicesUsed = data;
    let sumAdditional = additionalServices.reduce((ac: any, value: any) => {
      return data.includes(value.id) ? ac + value.price : ac;
    }, 0);
    
    cart[index].sumAdditional = sumAdditional;

    if (cart[index]) {
      dispatch(updateCart(cart, cartCount, totalPrice));
      setSpecialServicesCount(specialServicesIds.length)
      setOpenAdditionalServices(false)
    }
  };

  const uniqBy = (a: any, key: any) => {
    let seen = new Set();
    return a.filter((item: any) => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  };

  return (
    <Card elevation={0} sx={{ backgroundColor: 'white', width: 400, mt: 2 }}>
      <CardContent sx={{ p: '0 !important' }}>
        <Grid container padding={2}>
          <Grid item xs={3}>
            <Avatar 
              sx={{ width: 80, height: 80 }} 
              src={item.type === "subscription" ? item.Image_url : item.Images[0]} 
            />
          </Grid>

          <Grid item xs={7} container flexDirection='column' justifyContent='space-between'>
            {
              item.type === "subscription" ? <>
                <Typography variant="h6">{item[`Title_${i18n.locale}`]}</Typography>
                <Typography color="GrayText" variant="body1">
                  {item.Price} dhs/{t(`common:${item["Duration"]}`)}
                </Typography>
                <Typography color="GrayText" variant="caption">
                  {item[`Description_${i18n.locale}`]}
                </Typography>
              </> : <>
                <Typography variant="h6">{item[`Name_${i18n.locale}`]}</Typography>
                <Typography color="GrayText" variant="body1">
                  {item.Price} dhs{item.Unit === "m2" ? "/m2" : ""}
                </Typography>
                {
                  item.type !== "shop" && (
                    <Typography 
                      color="primary" variant="body2" 
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setOpenAdditionalServices(true)}
                    >
                      {t("common:Additional_services")} ({specialServicesCount})
                    </Typography>
                  )
                }
              </>
            }
          </Grid>

          <Grid item xs={2} container justifyContent='center' alignItems='center'>
            <Stack alignItems='center' justifyContent='flex-start'>
              <Typography variant="h6" color='GrayText'>
                {item.type === "subscription" ? 1 : quantity}
              </Typography>
              <Typography color='GrayText' variant="body1">
                {item.type === "subscription" ? item.Price: item.Price * quantity} dhs
              </Typography>

              {
                item.type !== "subscription" && (
                  <Stack direction='row' justifyContent='center' alignItems='center' gap={1}>
                    <IconButton
                      onClick={() => updateQuantity(-1)}
                      sx={{ p: 0 }}
                      color="primary" aria-label="upload picture" component="span"
                    >
                      <RemoveRounded />
                    </IconButton>
                    <IconButton
                      onClick={() => updateQuantity(1)}
                      sx={{ p: 0 }}
                      color="primary" aria-label="upload picture" component="span"
                    >
                      <AddRounded />
                    </IconButton>
                  </Stack>
                )
              }
            </Stack>
          </Grid>
        </Grid>
      </CardContent>

      <AdditionalServicesModal 
        additionalServices={additionalServices}
        specialServicesUsed={item.SpecialServicesUsed}
        open={openAdditionalServices} 
        handleClose={() => setOpenAdditionalServices(false)}
        handleSave={onAddionalServicesSaved}
      />
    </Card>
  )
}
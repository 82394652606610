import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUserData, updateCart } from '../actions';
import { loggingOut } from '../API/firebaseMethods';
import { t } from "../services/i18n";
import { ComplaintModalContext } from './AppBar';
import AvatarCrop from './Avatar/AvatarCrop';

export default function NavigationDrawer() {
  const [open, setOpen] = useState(false);

  const [openAvatarCropModal, setOpenAvatarCropModal] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState<any>(null);

  const { userData } = useSelector((state: any) => state.UserReducer);

  const photoInputRef = useRef<any>(null);

  function toggleDrawer(open: any) {
    setOpen(open);
  }

  function handleShowPhotoPicker() {
    photoInputRef.current.click();
  }

  function handleChangePhoto(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setAvatarSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setOpenAvatarCropModal(true);
    }
  }

  return <>
    <IconButton 
      size="large"
      edge="start"
      color="inherit"
      aria-label="open drawer"
      // sx={{ mr: 2 }}
      onClick={() => toggleDrawer(true)}
    >
      <MenuIcon />
    </IconButton>

    <Drawer
      anchor={'left'}
      open={open}
      onClose={() => toggleDrawer(false)}
    >
      <Stack paddingTop={2} justifyContent="center" alignItems='center' gap={2}>
        <Tooltip title={t("common:Change_my_Picture")}>
          <Avatar 
            onClick={handleShowPhotoPicker}
            sx={{ width: 80, height: 80, cursor: 'pointer' }} 
            src={userData.avatar} 
          />
        </Tooltip>
        <Typography>{userData.Name}</Typography>
      </Stack>
      <Items toggleDrawer={toggleDrawer} />
    </Drawer>

    <AvatarCrop 
      src={avatarSrc}
      open={openAvatarCropModal} 
      handleClose={() => setOpenAvatarCropModal(false)} 
    />

    <input 
      type='file' style={{ display: 'none' }}
      ref={photoInputRef}
      accept="image/*"
      // onChange={() => setOpenAvatarPreview(true)}
      onChange={handleChangePhoto}
    />
  </>
}

function Items({ toggleDrawer }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setOpenComplaintModal } = useContext(ComplaintModalContext);
  
  const { userData } = useSelector((state: any) => state.UserReducer);

  const { pathname } = useLocation();
  const hideLinks = (
    ['/address-selection', '/add-address', '/edit-address', '/shop-selection'].includes(pathname) &&
    !userData?.Shop_id
  );
  
  const links = [
    { name: t('common:home'), handleClick: () => navigate('/') },
    { name: t('common:call_cleanzy'), handleClick: () => navigate('/contact-cleanzy') },
    { name: t('common:Edit_Profile'), handleClick: () => navigate('/profile') },
    { name: t('common:My_Orders'), handleClick: () => navigate('/my-orders') },
    { name: t('common:My_Subscription'), handleClick: () => navigate('/my-subscription') },
    { name: t('common:My_addresses'), handleClick: () => navigate('/my-addresses') },
    { name: t('common:Terms_And_Conditions'), handleClick:() => window.open('https://cleanzy.ma/conditions', "_blank") },
    { name: t('common:Privacy_Policy'), handleClick: () => window.open('https://cleanzy.ma/privacy', "_blank") },
    { name: t('common:Add_Complaint'), handleClick: () => setOpenComplaintModal(true) },
  ]

  async function handleLogout() {
    dispatch(setUserData({}, false));
    dispatch(updateCart([], 0, 0));
    navigate('/login');
    await loggingOut();
    localStorage.setItem('token', '');
  }

  return (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      {
        !hideLinks && (
          <List>
            {links.map(({ name, handleClick}) => (
              <ListItem button onClick={handleClick} key={name}>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        )
      }
      <Divider sx={{ mt: hideLinks ? 3 : 0 }} />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary={t("common:LogOut")} />
        </ListItem>
      </List>
    </Box>
  )
};
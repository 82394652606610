import { PasswordRounded } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { showAlert } from "../../actions";
import * as yup from "yup";
import { resetPassword } from "../../API";
import { t } from "../../services/i18n";
import { LoadingButton } from "@mui/lab";

export default function EditPassword() {
	const dispatch = useDispatch();

  const { values, errors, touched, handleSubmit, handleChange, resetForm, isSubmitting } = useFormik({
		initialValues: {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
		},
		validationSchema: yup.object({
      oldPassword: yup
        .string()
        .matches(
          /^((?=.*[a-zA-Z]).{6,})$/,
          t("common:Password_must_be_longer_than_6_characters_and_must_consist_of_at_least_one_number")
        )
        .required(t("common:old_password_is_required")),
      newPassword: yup
        .string()
        .matches(
          /^((?=.*[a-zA-Z]).{6,})$/,
          t("common:Password_must_be_longer_than_6_characters_and_must_consist_of_at_least_one_number")
        )
        .required(t("common:new_password_is_required")),
      passwordConfirmation: yup
        .string()
        .matches(
          /^((?=.*[a-zA-Z]).{6,})$/,
          t("common:Password_must_be_longer_than_6_characters_and_must_consist_of_at_least_one_number")
        )
        .oneOf([yup.ref('newPassword'), null], t("common:The_password_doesnt_match"))
        .required(t("common:confrim_password_is_required")),
    }),
		async onSubmit(values) {
      let res = await resetPassword(values);
      if (res.error === false) {
        resetForm();
        dispatch(showAlert("success", t("common:your_password_updated_successfully")));
      } else {
        dispatch(showAlert("error", res.message || res.error));
      }
		},
	});

  return <>
    <Typography variant="h4">{t("common:Change_Password")}</Typography>
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth margin="normal" color='primary'
        name="oldPassword" label={t("common:Old_Password*")}
        placeholder="**********"
        type='password'
        autoFocus
        value={values.oldPassword} onChange={handleChange}
        error={touched.oldPassword && Boolean(errors.oldPassword)}
        helperText={touched.oldPassword && errors.oldPassword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PasswordRounded />
            </InputAdornment>
          ),
        }}
      />
    
      <TextField
        fullWidth margin="normal" color='primary'
        name="newPassword" label={t("common:New_Password*")}
        placeholder="**********"
        type='password'
        autoFocus
        value={values.newPassword} onChange={handleChange}
        error={touched.newPassword && Boolean(errors.newPassword)}
        helperText={touched.newPassword && errors.newPassword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PasswordRounded />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth margin="normal" color='primary'
        name="passwordConfirmation" label={t("common:Confirm_Password*")}
        autoFocus
        placeholder="**********"
        type='password'
        value={values.passwordConfirmation} onChange={handleChange}
        error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
        helperText={touched.passwordConfirmation && errors.passwordConfirmation}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PasswordRounded />
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        loading={isSubmitting}
        type="submit"
        fullWidth
        color='primary'
        variant="contained"
        sx={{ mt: 1, mb: 2 }}
        disableElevation
      >
        {t("common:Save")}
      </LoadingButton>
    </form>
  </>
}
import { Button, Container, Divider } from "@mui/material";
import EditInformations from "./EditInformations";
import EditPassword from "./EditPassword";
import { t } from "../../services/i18n";
import { useState } from "react";
import AccountDeleteConfirmation from "./AccountDeleteConfirmation";

export default function Profile() {
  const [open, setOpen] = useState(false);

  return (
    <Container maxWidth='sm' sx={{ pt: 5 }}>
      <EditInformations />
      <Divider sx={{ my: 3 }}></Divider>
      <EditPassword />
      <Divider sx={{ my: 3 }}></Divider>
      
      <Button
        onClick={() => setOpen(true)}
        fullWidth
        color='error'
        sx={{ mt: 1, mb: 2 }}
        disableElevation
      >
        {t("common:delete_your_account")}
      </Button>

      <AccountDeleteConfirmation open={open} handleClose={() => setOpen(false)} />
    </Container>
  )
}
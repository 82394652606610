import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { setUserData, showAlert, toggleLoader } from "../../actions";
import { useDispatch } from "react-redux";
import { t } from "../../services/i18n";
import { deleteUserAccount } from "../../API";
import { loggingOut } from "../../API/firebaseMethods";

export default function AccountDeleteConfirmation({ open, handleClose }: any) {
	const dispatch = useDispatch();

	async function handleAccountDelete() {
    let res = await deleteUserAccount();
    if (res.error === false) {
      loggingOut();
      dispatch(setUserData({}, false));
      dispatch(toggleLoader());
    } else {
      dispatch(showAlert("error", res.message || res.error,));
      return;
    }
    handleClose();
  }

	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>{t('common:Confirmation')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {t("common:Are_you_sure_you_want_to_delete_your_account_This_action_cannot_be_undone_after_pressing_Yes")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common:Cancel")}</Button>
        <Button onClick={handleAccountDelete} variant='contained' disableElevation>{t("common:Yes")}</Button>
      </DialogActions>
		</Dialog>
	);
}

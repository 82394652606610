export const fallback = "fr"; // Default Language If the user's phone language is not in the list or there is no pre-stored language

export const supportedLocales = {
  en: {
    name: "English",
    translationFileLoader: () => require("../lang/en.json"),

    // en is default locale in Moment
    momentLocaleLoader: () => Promise.resolve(),
  },
  fr: {
    name: "française",
    translationFileLoader: () => require("../lang/fr.json"),

    // en is default locale in Moment
    momentLocaleLoader: () => Promise.resolve(),
  },
  ar: {
    name: "عربي",
    translationFileLoader: () => require("../lang/ar.json"),
    // en is default locale in Moment
    momentLocaleLoader: () => Promise.resolve(),
  },
};

export const defaultNamespace = "common";

export const namespaces = ["common"];

import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import * as React from 'react';

export default function ShopRating({ rating, setRating }: any) {
  const [hover, setHover] = React.useState(-1);
  console.log(rating)
  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating
        value={rating}
        // getLabelText={getLabelText}
        onChange={(event: any, newValue: any) => {
          setRating(newValue);
        }}
        onChangeActive={(event: any, newHover: any) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {rating !== null && (
        <Box sx={{ ml: 2, display: 'flex', alignItems: 'center', color: '#faaf00' }}>
          <Box sx={{ fontSize: 25 }}>{hover === -1 ? rating : hover}</Box>
          /5
        </Box>
      )}
    </Box>
  );
}

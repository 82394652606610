/**
 *  get formated data as yyyy-mm-dd
 *  @param `Date Object`
 *  @returns `String` date
 */
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegex = /^((?=.*[a-zA-Z]).{6,})$/;
const phoneRegex = /(\+212)(6|7)([ \-_/]*)(\d[ \-_/]*){8}/;
/**
 *  Check if object empty
 * @param {Object} obj
 * @return {Boolean} `true` or `false`
 */
export const isObjEmpty = (obj) => {
  if (obj === undefined) {
    return true;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

/**
 * Checks if `value` is empty. Arrays, strings, or `arguments` objects with a
 * length of `0` and objects with no own enumerable properties are considered
 * "empty".
 *
 * @static
 * @memberOf _
 * @category Objects
 * @param {Array|Object|string} value The value to inspect.
 * @returns {boolean} Returns `true` if the `value` is empty, else `false`.
 * @example
 *
 * _.isEmpty([1, 2, 3]);
 * // => false
 *
 * _.isEmpty([]);
 * // => true
 *
 * _.isEmpty({});
 * // => true
 *
 * _.isEmpty('');
 * // => true
 */

export const isEmpty = (value) => {
  if (!value) {
    return true;
  }
  if (value === "null") {
    return true;
  } else if (Array.isArray(value) || typeof value === "string") {
    return !value.length;
  } else if (typeof value === "object") {
    return isObjEmpty(value);
  } else if (value !== null) {
    return false;
  }
  return true;
};

/**
 * check if Email valid
 *
 * @param {String} value
 *  @return {Boolean} `true` or `false`
 */
export const isValidEmail = (value) => {
  return emailRegex.test(value);
};

/**
 * check if phone valid
 *
 * @param {String} value
 *  @return {Boolean} `true` or `false`
 */
export const isValidPhone = (value) => {
  if (value.match(phoneRegex)) {
    return true;
  } else {
    return false;
  }
};

/**
 * check if Password valid
 *
 * @param {String} value
 *  @return {Boolean} `true` or `false`
 */
export const isValidPassword = (value) => {
  if (value.match(passwordRegex)) {
    return true;
  } else {
    return false;
  }
};

/**
 *  ucwords in JavaScript - Equivalent to PHP's ucwords() Returns a string with the first letter in upper case of each word.
 * @param {String} str
 * @returns {String}
 */
export const ucwords = (str) => {
  return (str + "").replace(/^(.)|\s+(.)/g, function ($1) {
    return $1.toUpperCase();
  });
};

/**
 *  get numbers of days between two days
 * @param {Date}  milliseconds time
 * @returns {Number} number
 */
export function getNumberOfDays(time) {
  const date1 = new Date();

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = time - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

import { AccountCircleOutlined, PhoneOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  InputAdornment,
  Stack,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { setUserPhoneAndName, showAlert } from "../../actions";
import { updateUserPhoneNumber } from "../../API";
import countriesCodes from "../../data/countriesCodes";
import i18n, { t } from "../../services/i18n";

export default function PhoneAndNameModal({ open, handleClose }: any) {
  const dispatch = useDispatch();

  const { values, errors, touched, handleSubmit, handleChange, resetForm, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      countryCode: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(t("common:all_fields_are_required")),
      countryCode: yup
        .string()
        .required(t("common:all_fields_are_required")),
      phone: yup
        .string()
        .required(t("common:all_fields_are_required")),
    }),
    async onSubmit({ name, phone, countryCode }) {
      const result = await updateUserPhoneNumber({ 
        phoneNumber: phone, 
        countryCode, 
        name 
      })
      if (result.success === true) {
        dispatch(setUserPhoneAndName({
          Phone: phone,
          CountryCode: countryCode,
          Name: name
        }))
        handleClose();
        resetForm();
      } else {
        dispatch(showAlert("error", t("common:phone_error")));
      }
    }
  });

	return (
		<Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ textAlign: 'left' }}>{t("common:Add_Name_and_Phone")}</DialogTitle>
        <DialogContent>
          <TextField
            dir={i18n.dir}
            fullWidth margin="normal" color='primary'
            name="name" label={t("common:Name*")}
            autoFocus
            value={values.name} onChange={handleChange}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlined />
                </InputAdornment>
              ),
            }}
          />

          <Stack direction='row' gap={2}>
            <Autocomplete
              sx={{ mt: 2, width: '50%' }}
              options={countriesCodes}
              autoHighlight
              onChange={(e, value) => setFieldValue('countryCode', value?.phone || '')}
              getOptionLabel={(option) => option.phone}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  dir={i18n.dir}
                  name='countryCode' label={t('common:countryCode')}
                  error={touched.countryCode && Boolean(errors.countryCode)}
                  helperText={touched.countryCode && errors.countryCode}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />

            <TextField
              dir={i18n.dir}
              fullWidth margin="normal" color='primary'
              name="phone" label={t('common:Phone')}
              sx={{ width: '50%' }}
              value={values.phone} onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common:cancel")}</Button>
          <LoadingButton 
            loading={isSubmitting}
            type="submit" 
            variant='contained' 
            disableElevation
          >
            {t("common:Save")}
          </LoadingButton>
        </DialogActions>
      </form>
		</Dialog>
	);
}

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { t } from "../../services/i18n";

export default function DeleteAddressModal({ open, handleClose, handleDelete }: any) {
	return (
		<Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'left' }}>{t("common:Confirmation")}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
        {t("common:Are_you_sure_you_want_to_delete_your_address_This_action_cannot_be_undone_after_pressing_Yes")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained' disableElevation>{t("common:Cancel")}</Button>
        <Button onClick={handleDelete}>{t("common:Yes")}</Button>
      </DialogActions>
		</Dialog>
	);
}

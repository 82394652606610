import { CheckCircle } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../actions";
import i18n, { t } from "../../services/i18n";
import { isEmpty } from "../../utils";
import AddSubToCartConfirmation from "./AddSubToCartConfirmation";

export default function Subscription({ subscription, screen }: any) {
  const [openAddSubToCart, setOpenAddSubToCart] = useState(false);

  const { userData } = useSelector((state: any) => state.UserReducer);
  const { cartItems } = useSelector((state: any) => state.LayoutReducer);
  const dispatch = useDispatch();

  const discountStyle = {
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    borderRadius: 2,
    p: 1,
    width: 45,
    height: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 15,
    right: 15,
  };

  const subscriptionCardStyle = {
    px: 3,
    pb: 3,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  function handleSubscribe() {
    // check if the user has already an active subscription
    if (
      userData.subscription &&
      !isEmpty(userData.subscription) &&
      userData.subscription.isActive
    ) {
      dispatch(
        showAlert(
          "warning",
          t(
            "common:you_cant_add_a_new_subscription_at_this_moment_because_you_have_already_an_active_subscription"
          )
        )
      );
      return;
    } else if (cartItems.length > 0) {
      if (cartItems[0]?.type !== "subscription") {
        dispatch(
          showAlert(
            "warning",
            `
          ${t("common:the_cart_contains_products_from_type")}
          ${t(`common:${cartItems[0].type}`)}
        `
          )
        );
        return;
      } else {
        // check if the cart has already a subscription
        if (cartItems.length === 1) {
          dispatch(
            showAlert(
              "warning",
              t("common:you_cant_choose_more_than_one_subscription_at_a_time")
            )
          );
          return;
        }
      }
    }
    setOpenAddSubToCart(true);
  }

  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: "white", position: "relative", height: "100%" }}
    >
      {subscription.Discount && screen !== "mySubscription" && (
        <Box sx={discountStyle}>-{subscription.Discount}%</Box>
      )}

      <CardContent sx={subscriptionCardStyle}>
        <div>
          <Stack padding={2} width="100%" alignItems="center">
            <Avatar
              sx={{ width: 120, height: 120, mb: 1 }}
              src={subscription.Image_url}
            />
            <Typography variant="h6">
              {subscription[`Title_${i18n.locale}`]}
            </Typography>
            <Typography color="GrayText">
              {subscription[`Description_${i18n.locale}`]}
            </Typography>
          </Stack>
          <Typography variant="h6">{t("common:Features")}</Typography>
          {subscription.Products?.map(
            (product: any, index: number) =>
              product.product && (
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  key={`${product.ProductId}-${product.Quantity}-index${index}`}
                >
                  <CheckCircle color="primary" />
                  <Typography color="GrayText">
                    {product.Quantity} x{" "}
                    {product.product[`Name_${i18n.locale}`]}
                  </Typography>
                </Stack>
              )
          )}
          <Typography variant="h6" marginTop={2}>
            {t("common:subscription_price")}
          </Typography>
          {subscription.Discount && (
            <Typography
              variant="h6"
              color="error"
              fontWeight="500"
              sx={{ textDecoration: "line-through" }}
            >
              {Math.round(
                (subscription.Discount / 100 + 1) * subscription.Price
              )}{" "}
              dhs
            </Typography>
          )}
          <Typography variant="h6" color="GrayText" fontWeight="500">
            {subscription.Price} dhs
          </Typography>
          <Typography color="GrayText" variant="caption">
            {t("common:to_be_paid_every")}{" "}
            {t(`common:${subscription.Duration}`)}
          </Typography>
        </div>
        {screen !== "mySubscription" && (
          <>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              sx={{ borderRadius: 0, mb: 4 }}
              onClick={handleSubscribe}
              disableElevation
            >
              {t("common:subscribe")}
            </Button>

            <AddSubToCartConfirmation
              open={openAddSubToCart}
              handleClose={() => setOpenAddSubToCart(false)}
              subscription={subscription}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

import { LoadingButton } from "@mui/lab";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText, TextField,
	Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { showAlert } from "../../actions";
import { addRate } from "../../API";
import i18n, { t } from "../../services/i18n";
import Rating from "./Rating";

export default function ShopRatingModal({ open, handleClose, type, order, isRated, setIsRated }: any) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.UserReducer);
  const [rating, setRating] = useState<number | null>(5);

  const { values, errors, touched, handleSubmit, handleChange, resetForm, isSubmitting } = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: yup.object({
			comment: yup
				.string()
				.required(t("common:all_fields_are_required")),
		}),
    async onSubmit({ comment }) {
			const deliveryGuyID = order?.deliveryDetails[0]?.deliveryGuyID;
			let values = {
				order: order.id,
				shop: order.shopId,
				rate: rating,
				comment,
				user: userData.uid,
				deliveryGuyID: deliveryGuyID?.id || deliveryGuyID || '',
				type,
			}

			let res = await addRate(values);
			if (res.error === false) {
				setIsRated({ ...isRated, [type]: true })
				handleClose()
				resetForm();
				dispatch(showAlert('success', t("common:your_data_updated_successfully")));
			} else {
				dispatch(showAlert('success', res.message || res.error));
			}
		}
  });

	return (
		<Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <DialogContentText>
						{t("common:Please_fill_all_required_fields_to_add_a_new_review_for_the_delivery")}
          </DialogContentText>
					<Typography variant='h6' marginTop={2}>Your rating</Typography>

					<Rating rating={rating} setRating={setRating} />

          <TextField
						dir={i18n.dir}
            fullWidth margin="normal" color='primary' 
            name="comment" label={t("common:Comment")}
            multiline
            rows={3}
            value={values.comment} onChange={handleChange}
            error={touched.comment && Boolean(errors.comment)}
            helperText={touched.comment && errors.comment}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{t("common:Cancel")}</Button>
          <LoadingButton 
						loading={isSubmitting}
						type="submit" 
						variant='contained' 
						disableElevation
					>
						{t("common:Save")}
					</LoadingButton>

        </DialogActions>
      </form>
		</Dialog>
	);
}

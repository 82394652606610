import actionTypes from "../actions/types";

const initialState = {
    title: null,
    body: null,
    opened: false,
};
  
const NotificationsReducer = (state, action) => { 
    state = state || initialState;
    switch (action.type) {
        case actionTypes.NOTIFICATION_DISPLAYED:
            const payload = action.payload;
            return {
                ...state,
                title: payload.title,
                body: payload.body,
                opened: true
            };
        case actionTypes.CLOSE_NOTIFICATION_DISPLAY:
            return {
                ...state,
                opened: false
            };
        case actionTypes.RESET_VALUES:
            return {
                ...state,
                title: null,
                body: null
            }
        default:
            return state;
    }
};
  
export default NotificationsReducer;
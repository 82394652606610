import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../images/logo.png";

import { useFormik } from "formik";
import * as yup from "yup";
import { Autocomplete, CircularProgress, InputAdornment, Stack } from "@mui/material";
import {
	EmailOutlined,
	PasswordRounded,
	PhoneOutlined,
	AccountCircleOutlined,
} from "@mui/icons-material";
import { registration } from "../API/firebaseMethods";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import SignUpMethods from "../components/SignUpMethods";
import { useEffect, useState } from "react";
import { t } from "../services/i18n";
import ForgotPasswordDialog from "../components/ForgotPasswodDialog";
import countriesCodes from "../data/countriesCodes";
import { LoadingButton } from "@mui/lab";

export default function Register() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const userState = useSelector((state: any) => state.UserReducer);
	const { loading } = useSelector((state: any) => state.LayoutReducer);

  const [openForgotPassword, setOpenForgotPassword] = useState(false);

	const { values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting } = useFormik({
		initialValues: {
			name: "",
			phone: "",
			countryCode: "",
			email: "",
			password: "",
			isAcceptGCU: false,
		},
		validationSchema: yup.object({
			name: yup
				.string()
				.required(t("common:Name_is_required")),
			phone: yup
				.string()
				.required(t("common:country_code_is_required")),
			email: yup
				.string()
				.email(t("common:Email_invalid_please_use_the_correct_format"))
				.required(t("common:Email_field_is_required")),
			password: yup
				.string()
				.matches(
					/^((?=.*[a-zA-Z]).{6,})$/, 
					t("common:Password_must_be_longer_than_6_characters_and_must_consist_of_at_least_one_number")
				)
				.required(t("common:Password_field_is_required")),
		}),
		async onSubmit({ name, phone, countryCode, email, password, isAcceptGCU }) {
			try {
				if (!isAcceptGCU) {
					dispatch(showAlert("warning", t("common:Please_Accept_first_the_terms_to_use_our_app")));
					return;
				}
				const res = await registration(email, password, name, countryCode, phone, t)
				if (res.error === false) {
					// Nav.replace("Main", { screen: "AddressSelection" });
					navigate('/address-selection');
				} else {
					dispatch(showAlert("error", res.message || res.error,));
				}
			} catch (error: any) {
				dispatch(showAlert("error", error.message,));
			}
		},
	});

	useEffect(() => {
    if (userState.isLoggedIn) {
      navigate("/");
    }
  }, [userState, navigate]);


	return (
		loading ? (
      <Box sx={{ height: '100vh' }}>
        <Stack sx={{ width: '100%', height: '100%' }} justifyContent='center' alignItems='center'>
          <CircularProgress size={80} />
        </Stack>
      </Box>
    ) : (
			<Container component="main" maxWidth="xs" sx={{ pt: 8 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<img src={logo} width={100} alt='' />
					<Typography component="h1" marginTop={3} variant="h5">
						{t("common:Registration_form_title")}
					</Typography>
					<Box sx={{ mt: 1 }}>
						<form onSubmit={handleSubmit}>
							<TextField
								fullWidth margin="normal" color='primary'
								name="name" label={t("common:Name*")}
								placeholder="Ali Alami"
								autoFocus
								value={values.name} onChange={handleChange}
								error={touched.name && Boolean(errors.name)}
								helperText={touched.name && errors.name}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<AccountCircleOutlined />
										</InputAdornment>
									),
								}}
							/>
							<Stack direction='row' gap={1}>
								<Autocomplete
									sx={{ mt: 2, width: '50%' }}
									options={countriesCodes}
									autoHighlight
									onChange={(e, value) => setFieldValue('countryCode', value?.phone || '')}
									getOptionLabel={(option) => option.phone}
									renderOption={(props, option) => (
										<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
											<img
												loading="lazy"
												width="20"
												src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
												srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
												alt=""
											/>
											{option.label} ({option.code}) +{option.phone}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											name='countryCode' label={t('common:countryCode')}
											error={touched.countryCode && Boolean(errors.countryCode)}
											helperText={touched.countryCode && errors.countryCode}
											inputProps={{
												...params.inputProps,
												autoComplete: 'new-password',
											}}
										/>
									)}
								/>

								<TextField
									sx={{ width: '50%' }}
									fullWidth margin="normal" color='primary'
									name="phone" label={t("common:Phone*")}
									placeholder="634654876"
									autoFocus
									value={values.phone} onChange={handleChange}
									error={touched.phone && Boolean(errors.phone)}
									helperText={touched.phone && errors.phone}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<PhoneOutlined />
											</InputAdornment>
										),
									}}
								/>
							</Stack>
							<TextField
								fullWidth margin="normal" color='primary' 
								name="email" label={t("common:Email*")}
								placeholder="Ali.alami@gmail.com"
								autoFocus
								value={values.email} onChange={handleChange}
								error={touched.email && Boolean(errors.email)}
								helperText={touched.email && errors.email}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<EmailOutlined />
										</InputAdornment>
									),
								}}
							/>
								
							<TextField
								fullWidth margin="normal" color='primary' 
								name="password" label={t("common:Password*")}
								type="password"
								placeholder="********"
								value={values.password} onChange={handleChange}
								error={touched.password && Boolean(errors.password)}
								helperText={touched.password && errors.password}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<PasswordRounded />
										</InputAdornment>
									),
								}}
							/>
							<FormControlLabel
								control={<Checkbox checked={values.isAcceptGCU} color="primary" />}
								name='isAcceptGCU'
								label={
									<Link
										sx={{ color: 'gray' }}
										onClick={(e) => {
											e.preventDefault();
											window.open('https://cleanzy.ma/conditions', "_blank");
										}}
									>
										{t("common:I_accept_terms_and_conditions")}
									</Link>
								}
								onChange={handleChange} 
							/>
							<LoadingButton
								loading={isSubmitting}
								type="submit"
								fullWidth
								color="primary"
								variant="contained"
								sx={{ mt: 1, mb: 2 }}
								disableElevation
							>
								{t("common:Register")}
							</LoadingButton>

							<Link 
								onClick={() => setOpenForgotPassword(true)}
								href="#" variant="body2" color="secondary"
								textAlign='center'
								sx={{ cursor: 'pointer', display: 'block', mb: 1 }}
							>
								{t("common:Forgot_Password")}
							</Link>
							<Link 
								variant="body2" color='secondary' 
								textAlign='center'
								sx={{ cursor: 'pointer', display: 'block' }}
								onClick={() => navigate('/login')}
							>
								{t("common:Already_have_an_account")} {t("common:Sign_in")}
							</Link>

							<SignUpMethods />
						</form>
					</Box>
				</Box>

				<Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 3, mb: 4 }}>
					{t("common:Copyright_cleanzy", { year: new Date().getFullYear() })}
				</Typography>

				<ForgotPasswordDialog open={openForgotPassword} handleClose={() => setOpenForgotPassword(false)} />
			</Container>
		)
	);
}

import { Add, DeleteOutlined, LocationOnRounded, ModeEditOutlined } from "@mui/icons-material";
import { Button, Container, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData, showAlert } from "../../actions";
import { deleteAddress } from "../../API";
import { t } from "../../services/i18n";
import DeleteAddressModal from "./DeleteAddressModal";

export default function MyAddresses() {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState<any>({});
  
  const { userData } = useSelector((state: any) => state.UserReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleDelete() {
    let values = { id: addressToDelete.id, isDefault: addressToDelete.Is_default };

    let res = await deleteAddress(values);
    if (res.error === false) {
      let user = JSON.parse(JSON.stringify(userData));
      user.addresses = user.addresses.filter((address: any) => address.id !== addressToDelete.id );
      dispatch(setUserData(user, true));
      setOpenDeleteModal(false);
      setAddressToDelete({});
      dispatch(showAlert('success', 'Address deleted successfully !'));
    } else {
      setOpenDeleteModal(false);
      dispatch(showAlert('error', res.message || res.error));
    }
    setOpenDeleteModal(false);
  }

  function handleOpenDeleteModal(address: any) {
    setAddressToDelete(address);
    setOpenDeleteModal(true);
  }

  return (
    <Container maxWidth='sm' sx={{ height: '100vh', pt: 5 }}>
      <Typography variant="h4">{t('common:My_addresses')}</Typography>
     
      <Stack direction='row' justifyContent='end'>
        <Button
          onClick={() => navigate('/add-address')}
          color='primary'
          variant="contained"
          sx={{ mt: 2, mb: 2, pl: 1  }}
          disableElevation
        >
          <Add sx={{ mr: 1 }} />
          {t("common:Add_new_Address")}
        </Button>
      </Stack>

      {
        <List dense>
          {
            userData.addresses.map((address: any, i: number) => <>
              <ListItem
                key={i}
                secondaryAction={
                  <Stack direction='row' gap={1}>
                    <IconButton edge="end" onClick={() => navigate('/edit-address', { state: address })}>
                      <ModeEditOutlined />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleOpenDeleteModal(address)}>
                      <DeleteOutlined />
                    </IconButton>
                  </Stack>
                }
              >
                <ListItemText 
                  primary={address.label} secondary={address.FirstLine} />
                {
                  address.Is_default && (
                    <ListItemAvatar>
                      <LocationOnRounded color="primary" />
                    </ListItemAvatar>
                  )
                }
              </ListItem>
              {i < userData.addresses.length - 1 && <Divider />}
            </>)
          }
        </List>
      }

      <DeleteAddressModal 
        open={openDeleteModal} 
        handleClose={() => setOpenDeleteModal(false)} 
        handleDelete={handleDelete}
      />
    </Container>
  )
}

// let res = await updateAddress(values);
//       if (res.error === false) {
//         showMessage({
//           message: t("common:Success"),
//           description: t("common:The_Address_was_updated_successfully"),
//           type: "success",
//         });

//         // lets update the global user addresses
//         let user = JSON.parse(JSON.stringify(userData));
//         // update old default address
//         if (values.new_address.Is_default) {
//           var foundIndex = user.addresses.findIndex(
//             (x) => x.Is_default === true
//           );
//           if (foundIndex !== -1) {
//             user.addresses[foundIndex].Is_default = false;
//           }
//         }
//         user.addresses[addressIndex] = values.new_address;

//         // dispatch user with new updated address
//         dispatch(setUserData(user, true));
import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import i18n, { t } from "../../services/i18n";
import { isEmpty } from "../../utils";

export default function ProductsTable({ order }: any) {
  return <>
    <Grid dir={i18n.dir} container marginTop={3}>
      <Grid item xs={6}>
        <Typography color='GrayText' fontWeight={500}>
          {t("products_title_table")}
        </Typography>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={6}>
          <Typography color='GrayText' fontWeight={500}>
            {t("nbr_title_table")}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color='GrayText' fontWeight={500}>
            {t("total_title_table")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>

    <Divider sx={{ my: 1 }} />

    <Box sx={{ mb: 3 }}>
      {
        order.products.map((product: any) => (
          <Grid dir={i18n.dir} container key={product.id}>
            <Grid item xs={6}>
              <Typography>{product[`Name_${i18n.locale}`]}</Typography>
              <Typography color='GrayText'>
                {product.Price} dhs 
                {
                  product.type === "subscription" ? (
                    "/ " + t(`common:${product.Duration}`)
                  ) : (
                    product.Unit === "m2" && "/m2"
                  )
                }
              </Typography>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={6}>
                <Typography>
                  {product.type === "subscription" ? 1 : 'x' + product.quantity}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography>
                  {product.type === "subscription" ? 1 : product.quantity * product.Price + ' dhs'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))
      }
    </Box>

    <TotalRow label={t("common:Total")} price={order?.totalGrossPrice + ' dhs'} />
    {
      order.delivery_fee !== 0 && (
        <TotalRow label={t("common:Delivery_Fee")} price={order?.delivery_fee + ' dhs'} />
      )
    }
    {
      
      !isEmpty(order.expressDeliveryFee) && order.expressDeliveryFee !== 0 && (
        <TotalRow label={t("common:Express_Delivery")} price={order?.expressDeliveryFee + ' dhs'} />
      )
    }
    {
      !isEmpty(order.coupon) && !isEmpty(order.coupon.value) && (
        <TotalRow label={t("common:Coupon")} price={`-${order?.coupon?.value || 0}%`} />
      )
    }
    {
      !isEmpty(order.additional_services_fee) && (
        <TotalRow label={t("common:Additional_services")} price={order?.additional_services_fee + ' dhs'} />
      )
    }
    <TotalRow label={t("common:Total_Paid")} price={order.total + ' dhs'} />

    <Typography color='GrayText' variant='caption' textAlign='left' sx={{ display: 'block', mt: 1 }}>
      {
        order?.paymentMethod === "creditCard" ? (
          t("common:Paid_with_credit_card")
        ) : (
          t("common:Paid_in_cash")
        )
      }
    </Typography>
  </>
}

function TotalRow({ label, price }: any) {
  return (
    <Grid dir={i18n.dir} container marginTop={1}>
      <Grid item xs={9}>
        <Typography fontWeight={500}>{label}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight={500}>{price}</Typography>
      </Grid>
    </Grid>
  )
}